body {
  overflow-x: hidden;
}

.blog-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 2rem 0;
  row-gap: 3.5rem;
}

@media (min-width: 768px) {
  .blog-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .blog-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1600px) {
  .blog-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  min-height: 40vh;
}

@media (min-width: 200px) and (max-width: 765px) {
  .blog-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
