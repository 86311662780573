@import "../../../assets/scss/style.scss";

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    gap: 1rem;
    flex-direction: column;
    align-items: normal;
  }
}

.child {
  @media (max-width: 400px) {
    width: 100%;
  }
}
.main_header {
  color: $dark;
  font-weight: 700;
  font-size: 40px;
  font-family: $plus_jakara_sans;
}

.sub_header {
  color: $dark;
  font-weight: 800;
  font-size: 32px;
  font-family: $plus_jakara_sans;
}

.small_header {
  color: $dark;
  font-size: 24px;
  font-weight: 700;
  font-family: $plus_jakara_sans;
}

.smaller_header {
  color: $dark;
  font-size: 20px;
  font-weight: 600;
  font-family: $inter;
}

@media (max-width: 1000px) {
  .main_header {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .main_header,
  .sub_header,
  .smaller_header {
    font-size: 24px; /* Hide the auth-image on smaller screens */
    font-weight: 700;
    margin-bottom: 0;
    font-family: $inter;
  }
}
