.notification-card {
    top: 20px;
    right: 20px;
    width: 350px;
    background: #ffffff;
    border-left: 4px solid #3d9be9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;
    padding: 12px;

    .btn-close{
      cursor: pointer;
      width: 0.5rem;
      height: 0.5rem;
      margin-top: 3px;
      box-shadow: none;
      outline: none;
    }

  }
  
  .icon-container {
    margin-right: 10px;
    opacity: 1;
    color: black;
  }
  