$font-family: Inter, sans-serif;

.inputFiledWrap {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 0 10px;
  margin-top: 1rem;
}

.daysInput {
  border: 0px !important;
  height: 35px;
  width: 4rem;
  background-color: #fff;
  border-color: #efefef;
  padding: 0;
}

.shareLinkWrap {
  .shareLink {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    // gap: 1rem;
  }

  .subShareLink {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .linkAndIcon {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .copylink {
    cursor: pointer;
  }

  .inputFiled {
    input {
      // width: 90px;
    }
  }

  .errorLabel {
    color: red;
  }

  .genratedButton {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.shareLinkEmail {
  .titleWrap {
    // padding-top: 10px;
    padding-bottom: 10px;
  }

  .title {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6f767e;
  }

  .formStyle {
    // padding-top: 10px;

    label {
      margin: 12px 0px;
    }
  }

  .email {
    display: flex;
    flex-direction: column;
  }
  .linkAndIcon {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .copylink {
    cursor: pointer;
  }

  .titleButton {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
  }

  .footertitle {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.generate-link-container {
  width: 100%;
  display: flex;
  gap: 1%;
  margin-bottom: 15px;
  margin-top: 1rem;
  button {
    width: 20%;
  }
  .linkAndIcon {
    width: 77%;
    margin-bottom: 0;
    label {
      max-height: 20px;
    }
  }
}
.subShareLink-container {
  align-items: center;
}

// Shared Blog
.shared-blog {
  .shared-expired {
    min-height: 40lvh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shared-blog-container {
    padding: 3% 17%;
  }

  .blog-content {
    border: 1px solid #efefef;
    // border-radius: 30px;
    font-style: $font-family;
    // margin: 3% 12%;
    padding: 3%;
  }

  .ql-video {
    display: block;
    margin: auto;
    height: 22rem;
    width: 100%;
  }

  .fixed-data {
    margin-top: 2rem;
    text-align: justify;

    a {
      color: #1a1d1f;
    }
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .generate-link-container {
    flex-direction: column;
    gap: 10px;
    justify-content: center !important;
    .linkAndIcon {
      width: 100% !important;
    }
  }
}
