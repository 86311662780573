/* styles.css */

.parent {
  height: 18rem;
}

.cover_photo {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.cover_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_photo {
  position: absolute;
  top: 32%; /* Adjusted from 28% to center vertically */
  left: 25%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 25%;
  border: solid #efefef;
  overflow: hidden;
}

.profile_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25%;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .cover_photo {
    height: 150px; /* Adjust height for smaller screens */
  }

  .profile_photo {
    width: 80px; /* Adjust width for smaller screens */
    height: 80px; /* Adjust height for smaller screens */
  }
}

@media (min-width: 200px) and (max-width: 786px) {
  .parent {
    width: 100%;
    height: 270px;
    img {
      top: -60px !important;
      left: 18% !important;
    }
  }
}
