.productDetailContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 20px;
  margin: 1rem 0;
  row-gap: 1rem;

  p {
    margin-bottom: 0 !important;
  }

  .p-dark {
    font-size: 12px !important;
  }

  div {
    overflow-wrap: break-word;
  }
}
