.product-container {
  padding: 20px 5rem 0 0;
  min-height: 120vh;
  display: flex;
  flex-direction: column;

  .product-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .info-part,
    .btn-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .product-image {
      width: 80px;
      height: 80px;
    }
  }
}

.Edit-blue-icon-container {
  color: #3d9be9 !important;
  .Edit-blue-icon {
    fill: #3d9be9;
  }
  &:hover {
    color: white;
    .Edit-blue-icon {
      fill: white;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1233px) {
  .product-container {
    padding: 2.5rem 3rem 0 3rem;
  }
}

.edit-container {
  padding-top: 2rem;
  padding-right: 2rem;

  .edit-div {
    margin-top: 1.5rem;
  }
}

.icon-div-container {
  margin-top: 2.4rem;
}

.edit-icon {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  i {
    font-size: 20px;
    line-height: 0;
    height: 20px;
    width: 20px;
  }
}

.add-icon {
  border: 1.5px solid #3d9be9;
  color: #3d9be9;
}

.remove-icon {
  border: 1.5px solid #be0f00;
  color: #be0f00;
}

@media (min-width: 200px) and (max-width: 900px) {
  .product-container {
    padding: 0;
  }

  .product-header {
    padding: 15px;
    flex-direction: column !important;
    align-items: start !important;
    gap: 20px;

    .info-part {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 15px !important;
    }
  }

  .main-box-product {
    padding: 0 15px 15px 15px;
  }

  .edit-container {
    padding-right: 0;
  }
}

///////////////////////////////////

.edit-div ::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
