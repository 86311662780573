@media (min-width : 600px) {
    .reset-contain {
        width: 30rem;
        margin: 4.5rem 0;
    }
}

@media screen and (max-width: 600px) and (min-width : 400px) {
    .reset-contain {
        margin: 2rem 0;
    }
}

@media (min-width: 200px) and (max-width:900px) {}