@import "../../../assets/scss/variables.scss";

.smallHeadingDropdownWrap {
  .labelWrap {
    height: 37px;
    padding: 11px 13px;

    i {
      margin-top: 0;
      margin-right: -2px;
    }
  }

  .title {
    margin-bottom: 0;
    line-height: 14px;
    font-size: 13px !important;
  }
}

.largeHeadingDropdownWrap {
  .labelWrap {
    height: 47px;
    padding: 13px 16px;
    align-items: center;

    i {
      margin-top: 0;
      margin-right: -2px;
    }
  }

  .title {
    margin-bottom: 0;
    line-height: 14px;
    font-size: 15px !important;
  }
}

.title {
  font-family: $inter;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  min-width: max-content;
}

.labelWrap {
  position: relative;
  border: 1px solid #ced5de;
  width: 100%;
  height: 42px;
  border-radius: 30px;
  padding: 11px 17px;
  display: flex;
  gap: 10px;
  cursor: pointer;

  i {
    margin-top: 2px;
  }
}

.activeLabel {
  border: 1px solid #1a1d1f;
  background-color: #f4f4f4;
}

.positionStyles {
  position: absolute;
  // top: 50px;
  // bottom: 0;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 1rem;
  z-index: 1;
  min-height: 150px;
  // height: 360px;
  width: 300px;
  overflow-y: visible;
  margin-top: 10px;
  // margin-left: 15px;
  min-width: 245px;
  max-width: 245px;
}

.bottomContainer {
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

.customBtn {
  height: 40px !important;
  padding: 0 18px !important;
  font-size: 15px;
  font-weight: 500 !important;
}
