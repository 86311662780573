.edit-form-div {
  padding: 1rem 0;
}

.certification-header,
.certification-detail,
.education-header,
.education-detail,
.company-header,
.company-detail {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certification-detail,
.education-detail {
  margin-bottom: 0.5rem;
}

.remove {
  color: #be0f00;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#MyProfile {
  .view-people-label,
  .view-people-info,
  .view-people-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1a1d1f;
  }

  .view-people-info {
    font-weight: 500;
    word-wrap: break-word;
    color: #6f767e;
  }
}
