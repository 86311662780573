.user-team{
    margin-top: 2.5rem;
}

#user-team::-webkit-scrollbar, .user-team-container::-webkit-scrollbar {
    display: none;
}

.user-team-container{
    display: flex;
    gap: 1.5rem;
    overflow-x: auto;
    margin-top: 1rem;
}
