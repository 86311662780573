@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
 .content-sharing-shimmer-conatiner{
    .content-sharing-shimmer-item{
        display: flex;
        width: calc( 100vw - 19rem);
        justify-content: space-between;
        margin-bottom: 10px;
        margin-right: 1rem;
        margin-top: 1rem;
        background: none;
        .content-sharing-shimmer-item-single{
            width: 150px;
            height: 30px;
        }
    }
    .shimmer-items-border-wrapper{
        width: 76vw;
        height: 1px;
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }

 }
