.thought-container {
  padding: 10px 50px 10px 20px;
}

.noContentContainer {
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
}

.detail-main {
  color: "#1A1D1F";
  font-weight: 800;
  font-size: "14px";
  text-wrap: pretty !important;
}

.detail-date {
  color: var(--80, #6f767e) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.14px;
}

.thought-leadership-view {
  width: 80%;
  min-height: 100vh;
}

@media (min-width: 200px) and (max-width: 550px) {
  .main-blog-responsive {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    .search {
      width: 100% !important;
    }
    .filter-blog {
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
  .thought-container {
    padding: 20px;
  }
  .date-tab-responsive {
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    .dot-dark {
      margin-left: 0 !important;
    }
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  .thought-leadership-view {
    width: 100% !important;
  }
  footer:is(.footer-responsive) {
    width: 100% !important;
  }
}
