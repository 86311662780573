@import "../../../assets/scss/variables.scss";
/* Breadcrumb container */
.breadcrumb {
  //   padding: 10px;
  padding-left: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 10px;
}

/* Breadcrumb items */
.breadcrumb-item {
  display: inline-block;
  //   margin-right: 5px; /* Adjust spacing between breadcrumb items */
}

/* Link styles */
.breadcrumb-item span {
  text-decoration: none;
  color: $dark; /* Change color for active link */
  cursor: pointer;
  font-weight: 500 !important;
}

.breadcrumb-item span:hover {
  text-decoration: underline;
}

.breadcrumb-item::before {
  display: none;
}

/* Separator styles */
.breadcrumb-item:not(:last-child)::after {
  content: ">"; /* Separator character */
  margin-left: 5px; /* Adjust spacing between separator and next breadcrumb item */
  color: #6c757d; /* Separator color */
}

.breadcrumb-item.active span,
.breadcrumb-item.active span:hover {
  color: #3d9be9 !important; /* Link color */
  font-weight: 500;
  cursor: default;
  text-decoration: none;
}

@media (min-width: 200px) and (max-width: 900px) {
  .breadcrumb {
    padding-left: 15px;
  }
}
