$inter: Inter, sans-serif;
/* Base styles for all screen sizes */
body {
  @media (min-width: 200px) and (max-width: 765px) {
    ::-webkit-scrollbar {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .hor-scroll-none {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.h-j,
.h1-j,
.h2-j,
.h3-j,
.h4-j,
.h5-j,
.h6-j {
  font-family: Plus Jakarta Sans, sans-serif !important;
}

.h-i,
.h1-i,
.h2-i,
.h3-i,
.h4-i,
.h5-i,
.h6-i {
  font-family: $inter;
}

.h24 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.75rem !important;
}

.h-j,
.h-i {
  font-size: 30px;
  font-weight: 700;
}

.h1-i,
.h1-j {
  font-size: 28px;
  font-weight: 700;
}

.h2-i,
.h2-j {
  font-size: 20px;
  font-weight: 700;
}

.h3-i,
.h3-j {
  font-size: 18px;
  font-weight: 800;
}

.semi-light {
  font-weight: 600 !important;
}

.semi {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500 !important;
}

.light {
  font-weight: 400 !important;
}

.h4-i,
.h4-j {
  font-size: 16px;
  font-weight: 700;
}

.p,
.p-dark,
.p-light-small,
.p-dark-light,
.p-grey,
.p16 {
  color: var(--80, #6f767e);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.14px;
}

.p16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.p-grey {
  color: #4e5660;
  font-weight: 500;
}

.p-dark {
  font-weight: 500;
  color: #1a1d1f;
}

.p-dark-light {
  color: #1a1d1f;
}

.p-light-small {
  font-size: 12px;
  font-weight: 500;
}

.p-dark-small {
  font-size: 12px;
  font-weight: 500;
  color: #1a1d1f;
}

.view-more {
  color: var(--PA-Primary, #3d9be9);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: -0.14px;
  text-align: left;
  cursor: pointer;
}

/* Responsive styles for mobile screens */

@media (max-width: 768px) {
  .h-j,
  .h1-j,
  .h2-j,
  .h3-j,
  .h4-j,
  .h5-j,
  .h6-j,
  .h-i,
  .h1-i,
  .h2-i,
  .h3-i,
  .h4-i,
  .h5-i,
  .h6-i {
    font-size: 18px;
    /* Adjust as needed for smaller screens */
  }

  .h24 {
    font-size: 19px;
    /* Adjust as needed for smaller screens */
  }

  .p,
  .p-dark,
  .p-light-small,
  .p-grey,
  .p16 {
    font-size: 12px;
    /* Adjust as needed for smaller screens */
    line-height: 18px;
    /* Adjust as needed for smaller screens */
  }

  .p16 {
    font-size: 14px;
    /* Adjust as needed for smaller screens */
    line-height: 20px;
    /* Adjust as needed for smaller screens */
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.edit-blue-icon {
  fill: #4e5660;
}
.Add-icon-btn-blue {
  fill: #3d9be9;
}
svg {
  overflow: visible;
}

.ql-editor {
  color: #1a1d1f;
}
.ql-editor ul > li::before {
  color: #6f767e !important;
}

.ql-snow {
  color: var(--80, #6f767e) !important;

  .ql-editor a {
    text-decoration: underline !important;
  }
}
.aboutCompanyWrap {
  .quill-change {
    .ql-container {
      border: none !important;
      .blog-content {
        padding: 0 !important;
        ol {
          padding-left: 1.5em;
        }
        li {
          padding-left: 11px !important;
          ::before {
            margin-top: 2px !important;
          }
        }
      }
    }
  }
}
@media (min-width: 200px) and (max-width: 700px) {
  .p,
  .p-dark {
    font-size: 14px;
    line-height: 20px;
  }
  .p-dark {
    line-height: 18px;
  }
  .h4-j {
    font-size: 16px;
  }
  .h1-i {
    font-size: 24px !important;
  }
  .h2-j {
    font-size: 20px !important;
    font-weight: 700;
  }
  .p-grey {
    font-size: 14px !important;
  }
}

.error-text {
  font-weight: 500;
  font-size: $danger;
  color: $danger;
}
