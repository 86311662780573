.resource-page-container {
  display: flex;
}

.resource-container {
  padding: 1rem 3rem 0 0;
  min-height: 150vh;
}

.resource-view-container {
  padding: 1.5rem 3.5rem !important;
  border: 1px solid #efefef;
  border-top: 0px !important;
  width: 75%;
  margin: 0 0 30px 0;
  // margin: 30px;
}

.extra-blogs {
  width: 25%;
  padding: 1rem;
}

.resource-container {
  padding: 1rem 3rem 0 0;
  min-height: 150vh;
}

.resource-view-container {
  padding: 2.5rem 5rem;
  border: 1px solid #efefef;
  // margin: 30px;
}

.extra-blog {
  margin-bottom: 10px;
  cursor: pointer;
  .view-more {
    text-transform: capitalize;
  }

  h4 {
    overflow-wrap: break-word;
    font-size: 15px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1233px) {
  .resource-container {
    padding: 1rem 3rem 0 3rem;
  }
}

.ql-video {
  display: block;
  margin: auto;
  height: 20rem;
  width: 40rem;
}

.content img {
  display: block !important;
  margin: auto !important;
  // width: 40rem;
}

.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

@media (min-width: 200px) and (max-width: 765px) {
  .resource-container {
    padding: 15px;
    width: 100%;
  }
  .filter-item-container {
    flex-wrap: nowrap !important;
    width: 100%;
    overflow: scroll;
    .filter-item {
      min-width: max-content;
    }
  }
  .library-container-dropdowm {
    flex-direction: column;
    align-items: normal !important;
    gap: 15px;
  }
  .ql-video {
    display: block;
    margin: auto;
    height: 15rem;
    width: 100%;
  }
}

.library-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-content {
  img {
    display: block !important;
    margin: 8px auto !important;
    // width: 40rem;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .resource-page-container {
    flex-direction: column;
  }
  .resource-container,
  .extra-blogs {
    min-width: 100vw;
  }
}
