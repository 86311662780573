.form_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.form_footer button {
  flex: 1;
}

// Password Strength 
.password_strength {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.condition_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.condition {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #CCD1DF;
  transition: background-color 0.3s;
}

.condition.met {
  background-color: #83BF6E !important;
}

.conditions_not_met .condition{
  background-color: #BE0F00;
}

.condition_heading{
  color: #1E273B;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; 
  margin-top: 20px;
  margin-bottom: 0;
}

.condition_description{
  color: #82899A;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
}

.inline_display {
  display: inline;
}

.btn {
  margin-top: 20px;
}
