.insight-info-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid var(--30, #efefef);
  padding: 24px 22px;
  margin-bottom: 15px;

  span {
    color: #1a1d1f;
    font-size: 18px;
    font-weight: 600;
    font-family: Inter;
  }

  .insight-info {
    display: flex;
    align-items: start;
    max-width: 80%;

    .info {
      display: flex;
      flex-direction: column;
      max-width: 80%;
    }
  }

  .insight-thumbnail {
    object-fit: cover;
    border-radius: 8px;
    height: 84px;
    width: 84px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
