.customBtn {
  height: 40px !important;
  padding: 0 18px !important;
  font-size: 15px;
  font-weight: 500 !important;
}

.bottomContainer {
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
}
