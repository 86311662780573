.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: #f8d7da; /* Light red background color */
    border: 1px solid #f5c6cb; /* Red border color */
    color: #721c24; /* Dark red text color */
    border-radius: 5px;
    margin: 20px;
  }
  
  .error-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .error-message {
    font-size: 1.2em;
    text-align: center;
  }
  