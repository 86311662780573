@import "../../../assets/scss/variables.scss";

.cardRoad {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  padding: 10px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.cardRoad img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover;
}

.cardImgMain {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.card-content {
  padding: 16px;
}

.profileName {
  color: var(--100, #1a1d1f);
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
  width: 75px;
  max-width: 85px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profilePosition {
  color: var(--80, #6f767e);
  font-family: $inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.14px;
  // width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
}

.viewProfileBtn {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 10px;
  width: 100%;
}

@media (min-width: 200px) and (max-width: 480px) {
  .card {
    min-width: 55%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .card {
    min-width: 30%;
    padding-left: 0;
    padding-right: 0;
  }
}
