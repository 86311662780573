.view-info-container {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;

  .label {
    flex: 0 0 250px;
    margin-right: 10px;

    color: #6f767e;
    font-weight: 500;
    font-size: 14px;
  }

  .data {
    flex: 1;
    font-weight: 500;
    color: #1a1d1f;
    font-size: 14px;
    display: flex;
    align-items: center;
    .colon {
      display: inline;
      color: #6f767e;
      font-weight: 500;
      font-size: 14px;
      margin-right: 1.5rem;
    }
  }

  .view-request-dropdown {
    display: flex;
    width: 70%;
    align-items: center;
  }
}

.request-info-container:first-child {
  margin-top: 1.5rem;
}

.edit-video-icon {
  fill: #4e5660;
}
.revoke-conatiner {
  position: relative;
}
.revoke-div-container {
  background: wheat;
  padding: 10px 15px;
  border-radius: 10px;
  position: absolute;
  bottom: -25px;
  left: 10px;
}

.Revoke-confirmation-btns {
  display: flex;
  width: 100%;
  justify-content: space-around;
  button {
    width: 40%;
  }
}
.link-ellipse-container {
  width: 276px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.data {
  i {
    margin-top: 15px;
    margin-left: -17px;
  }
}

.link-ellipse-container-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  :first-child {
    width: 77%;
  }
  :last-child {
    width: 23%;
    svg {
      width: 18px !important;
    }
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  .label {
    flex: 0 0 90px !important;
  }
  .sharedConetent-bottom-margin {
    margin-bottom: 0px !important;
    div {
      div {
        padding-left: 10px;
      }
    }
  }
  .link-ellipse-container {
    width: 218px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .data {
    i {
      margin-top: 15px;
      margin-left: -17px;
    }
  }
  .sharedConetent-top-margin {
    margin-top: 8px !important;
    width: 100vw !important;
  }
  .sharedConetent-padding-left-phone {
    padding: 0 10px;
    .search {
      width: 250px !important;
    }
    .filter-box {
      scale: 0.9;
      top: calc(100% - 6px);
      right: -23px;
    }
  }
}
@media (min-width: 200px) and (max-width: 480px) {
  .sharedConetent-padding-left-phone {
    .filter-container {
      &:nth-child(2) {
        i {
          margin-right: 0 !important;
        }
      }
    }
    .filter-box {
      scale: 0.7;
      top: calc(100% - 41px);
      right: -57px;
    }
  }
}
@media (min-width: 480px) and (max-width: 900px) {
  .label {
    flex: 0 0 250px !important;
  }
  .link-ellipse-container {
    width: 32ch !important;
  }
}

@media (min-width: 200px) and (max-width: 600px) {
  .label {
    flex: 0 0 90px !important;
  }
}

@media (min-width: 200px) and (max-width: 370px) {
  .link-ellipse-container-wrapper {
    width: 70% !important;
  }
}
