.type-div {
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}


@media (min-width: 200px) and (max-width:900px){
    .type-div {
        // margin-bottom: 20px !important;
    }
}
