.contentContainer {
  padding: 1rem 60px 1rem 0;
}

.dashboardContentContainer {
  padding: 1rem 60px;
  padding-left: 0;
}

@media screen and (max-width: 1233px) {
  .dashboardContentContainer {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .contentContainer {
    padding: 1rem;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .dashboardContentContainer {
    padding-left: 0 !important;
    padding-right: 0;
  }
}
