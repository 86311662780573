.video-dara-room-container {
  width: 80%;
  margin: 2% 10%;
  padding: 20px;
  font-style: "Inter";
  // background-color: pink;
  border: 1px solid #efefef;
  border-radius: 10px;
  .Date-header-container {
    display: flex;
  }
}

.video-div {
  margin-top: 40px;
  height: 420px;
  flex: 2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-div iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#hidden-iframe {
  display: none;
}

.pdf-iframe {
  width: 100%;
  height: 80vh;
}

.Data-room-item-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  .Data-pic-header-container {
    display: flex;
    gap: 15px;
    img {
      width: 125px;
      aspect-ratio: 3/2;
    }
    .Date-header-container {
      display: flex;
      flex-direction: column;
      gap: 7px;
      justify-content: center;
      .date-header {
        font-size: 14px;
      }
      h3 {
        font-size: 17px;
        margin-bottom: 0;
      }
      .p {
        font-size: 13px;
      }
    }
  }
  button {
    height: 40px !important;
    padding: 0 18px !important;
  }
}

.shared-expired {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-display-none-web {
  display: none;
}

@media (min-width: 200px) and (max-width: 900px) {
  .Data-room-item-container {
    flex-direction: column !important;
    gap: 15px;
  }
  .video-display-none {
    display: none !important;
  }
  .video-display-none-web {
    display: block !important;
  }
  .data-header-vide0-container {
    display: flex;
    gap: 5px;
    flex-direction: column !important;
    align-items: start !important;
  }

  .shared-audio-image-div {
    max-height: 300px !important;
    height: 300px !important;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .video-data-romm-btns-conatiner {
    scale: 0.8 !important;
    transform-origin: center !important;
    overflow-wrap: break-word;
  }

  .shared-audio-image-div {
    max-height: 200px !important;
    height: 200px !important;
  }
}

.shared-audio-image-div {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 12px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
