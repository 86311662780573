@media (min-width: 200px) and (max-width: 800px) {
  .banner-image {
    img {
      height: 240px !important;
      object-fit: cover;
    }
  }
}

.banner-image {
  .coverStyle {
    background-size: cover;
    background-position: center;
    height: 20rem; // Adjust the height as needed
    position: relative;
    border-radius: 30px;
    border: 1px solid #efefef;
    width: 100% !important;
    aspect-ratio: 3 / 2;
  }
  .bannerStyle {
    background-size: cover;
    background-position: center;
    height: 45vh;
    position: relative;
    border-radius: 30px;
    width: 100%;
  }
}
