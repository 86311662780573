@import "../../../assets/scss/variables.scss";

.trial-banner,
.subscription-ended-banner {
  height: 47px;
  background-color: $primary;
  display: flex;
  align-items: center;
  color: $white;
  font-size: 14px;
  justify-content: space-between;
  display: flex;
  top: 0;
  z-index: 1000;
  position: sticky;

  padding: 0 1rem;

  a {
    color: $white;
  }
}

.subscription-ended-banner {
  background-color: $danger;
  justify-content: center;
}

.trial-icon-div {
  svg,
  path {
    fill: $white;
  }
}
