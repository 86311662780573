@import "../../../assets/scss/style.scss";
.contact_template {
  margin-top: 40px;
  display: flex;
  gap: 28px;
}

.form_container {
  width: 65%;
}

.contact_info_container {
  flex: 1;
  padding: 32px; /* Adjust padding as needed */
  border-radius: 20px;
  border: 1px solid $light;
  height: max-content;
  background: $white;
  color: $dark;

  .info {
    display: flex;
    align-items: center;
    margin-top: 20px;
    i {
      width: 20px;
      height: 20px;
    }
    path {
      fill: $dark;
    }
    span {
      margin-bottom: 0 !important;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact_template {
    flex-direction: column;
  }

  .form_container,
  .contact_info_container {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .contact_template {
    gap: 1rem;
  }
  .contact_info_container {
    padding: 1.25rem;
  }
}
