.shared_blog {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1a1d1f !important;
  }

  h1 {
    font-weight: bolder;
  }
  p {
    font-size: 16px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  template {
    display: none;
  }
  a {
    background-color: transparent !important;
    text-decoration: none !important;
    font-weight: 400 !important;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  b,
  strong {
    font-weight: 700;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  pre {
    overflow: auto;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  button {
    border-radius: 0;
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input {
    line-height: normal;
  }
  input[type="checkbox"],
  input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  textarea {
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
  }
  [tabindex="-1"] {
    outline: none;
  }
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    border-width: 0;
  }
  input::-ms-clear {
    display: none;
  }
  input[type="file"]::-ms-browse,
  input[type="file"]::-webkit-file-upload-button {
    background: transparent;
    border-width: 0;
    color: currentColor;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  li {
    display: inline-block !important;
  }

  a {
    color: #1675e0;
    color: var(--rs-text-link);
    text-decoration: none;
  }
  a:focus,
  a:hover {
    color: #0a5dc2;
    color: var(--rs-text-link-hover);
  }
  a:active,
  a:focus,
  a:hover {
    text-decoration: underline;
  }
  a:active {
    color: #004299;
    color: var(--rs-text-link-active);
  }
  img {
    vertical-align: middle;
  }
  [role="button"] {
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: inherit;
    font-weight: bolder;
    margin: 0;
  }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    font-weight: 400;
    line-height: 1;
  }
  h1 {
    font-size: 46px;
    line-height: normal;
    margin-bottom: 15px !important;
  }

  h2 {
    font-size: 36px;
    line-height: 50px;
  }
  h3 {
    font-size: 28px;
    line-height: 42px;
  }
  h4 {
    font-size: 22px;
    line-height: 34px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  h6 {
    font-size: 16px;
    line-height: 22px;
  }
  p {
    margin: 0;
  }
  p + p {
    margin-top: 8px;
  }
  small {
    font-size: 85%;
  }
  mark {
    background-color: #fff9e6;
    background-color: var(--rs-bg-warning);
    font-weight: bolder;
    padding: 0.2em;
  }
  ol,
  ul {
    margin-bottom: 10px;
    margin-top: 0;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  ul,
  ol {
    display: grid;
  }

  li {
    margin-bottom: 0;
    font-size: 16px;
  }
  dl {
    margin-bottom: 10px;
    margin-top: 0;
  }
  dd,
  dt {
    line-height: 1.42857143;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 10px;
    margin-left: 0;
  }

  .line-height-normal,
  .ql-lineHeight span[data-value="normal"]::before {
    line-height: normal;
  }
  .line-height-tight,
  .ql-lineHeight span[data-value="tight"]::before {
    line-height: 1;
  }

  .line-height-normal-tight,
  .line-height-normalTight,
  .ql-lineHeight span[data-value="normal-tight"]::before {
    line-height: 1.5;
  }

  .line-height-comfortable,
  .ql-lineHeight span[data-value="comfortable"]::before {
    line-height: 2;
  }
  .line-height-spacious,
  .ql-lineHeight span[data-value="spacious"]::before {
    line-height: 2.5;
  }

  .line-height-extra-spacious,
  .line-height-extraSpacious,
  .ql-lineHeight span[data-value="extra-spacious"]::before {
    line-height: 3;
  }
}

.shared-no-border {
  border: 0px !important;
  padding: 0;
}
.ql-size-12px {
  font-size: 12px !important;
}

.ql-size-13px {
  font-size: 12px !important;
}

.ql-size-14px {
  font-size: 14px !important;
}

.ql-size-16px {
  font-size: 16px !important;
}

.ql-size-18px {
  font-size: 18px !important;
}

.ql-size-20px {
  font-size: 20px !important;
}

.ql-size-25px {
  font-size: 25px !important;
}

.ql-size-30px {
  font-size: 30px !important;
}

.ql-size-35px {
  font-size: 35px !important;
}

.ql-size-38px {
  font-size: 38px !important;
}

@media (min-width: 200px) and (max-width: 900px) {
  .shared-blog-container {
    padding: 10px !important;
  }
  .blog-content {
    padding: 10px !important;
  }
}
