.btn {
  margin-top: 0;
  border-radius: 24px !important;
  height: 48px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 24px !important;
  font-weight: 600 !important;
  min-width: max-content;
}

.btn-primary {
  background-color: #3d9be9 !important;
  border: none !important;
}

.btn-sm {
  height: 40px !important;
  padding: 0 20px !important;
}

.btn-xs {
  height: 35px !important;
  padding: 2px 16px !important;
  font-size: 13px;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #5ea8f8 !important;
}

.btn-primary:disabled {
  background-color: #9a9fa5 !important;
  opacity: 1 !important;
}

.btn-outline-dark {
  border: 1px solid var(--50, #c0c4c9) !important;
  color: $dark;
}

.btn-outline-primary {
  border: 1px solid $primary !important;
  color: $primary !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: $primary;
}

.btn-dark {
  background-color: $dark !important;
  color: #fff !important;
}

.btn-outline-dark:hover,
.btn-outline-dark:active {
  background-color: $light !important;
  border: 1px solid var(--50, #c0c4c9) !important;
  color: $dark !important;
}

.btn-outline-danger {
  color: $danger !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:active {
  background-color: $danger;
  color: #fff !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: $primary;
  border: 1px solid $primary;
  color: #fff;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

@media screen and (max-width: 400px) {
  .responsive-btn {
    width: 100%;
  }
}

.rich-text-editor-container {
  ul {
    li {
      cursor: text !important;
      display: flex;
    }
    li::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="14" height="14"><path d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/></svg>');
      margin-right: 8px;
      margin-top: 2.5px;
      // Adjust the spacing between the icon and the conten
    }
  }

  h4 {
    font-size: 1em;
    text-align: justify;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.ql-no-border {
  border: 0px !important;
}
.btn-reset {
  margin-top: 0;
  border-radius: 24px !important;
  height: 40px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 24px !important;
  font-weight: 600 !important;
  min-width: max-content;
}
