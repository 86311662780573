@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
 .shimmerResource-container{
    width: calc(100vw - (18rem +  60px));
    height: 100vh;
    padding: 1rem 0;
    .shre-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background: none;
        .shre-heading{
            width: 250px;
            height: 100%;
        }
        .shre-search{
            width: 400px;
            height: 100%;
            border-radius: 20px;
        }
        .shre-filter-item{
            width: 20%;
            height: 100%;
            border-radius: 20px;
        }
        .shre-filter-header{
            display: flex;
            gap: 10px;
            background: none;
            width: 100%;
        }
    }
    .shre-card-conatiner{
        width: 100%;
        display: flex;
        gap: 3%;
        flex-wrap: wrap;
        background: none;
        margin-top: 40px;
        .shre-card-item{
            width: 30%;
            background: none;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 25px;
            .shre-card-img{
                width: 100%;
                height: 30vh;
                border-radius: 20px;
            }
            .shre-card-heading{
                width: 200px;
                height: 4vh;
                margin-left: 10px;
            }
            .shre-card-small-item{
                width: 120px;
                height: 4vh;
                margin-left: 10px;
            }
            .shre-card-description{
                width: calc(100% - 10px);
                height: 7vh;
                margin-left: 10px;
            }
        }
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
 }

 @media (min-width: 200px) and (max-width:760px) {
    .shimmerResource-container{
        width: 100%;
        height: auto !important;
        .shre-card-item{
            width: 100vw !important;
            .shre-card-description{
                height: 4vh !important;
            }
        }
    }
 }
 @media (min-width: 760px) and (max-width:900px) {
    .shre-card-heading{
        width: 40vw !important;
    }
    .shre-card-small-item{
        width: 22vw !important;
    }
    .shre-card-item{
        width: 40vw !important;
    }
    .shre-card-conatiner{
        width: 100vw !important;
    }
 }