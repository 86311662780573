.content-sharing-phone-container{
    // border:1px solid #efefef;
    // border-radius: 10px;
    width: 100vw;
    // margin: 2vw;

    display: flex;
    flex-wrap: wrap;
    .sharing-details-phone-wrapper{
        width: 48%;
        border:1px solid #efefef;
        border-radius: 10px;
        margin: 10px 1%;
        padding-bottom: 5px;
        overflow-wrap: break-word;
        .sharing-details-content-conatiner{
            border:1px solid #efefef;
            border-radius: 10px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
            row-gap: 10px;
            padding: 10px;
            margin: 10px;
            margin-bottom: 5px;
            .detail-contet-item-phone{
            }
        }
        .sharing-details-phone-btns{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                scale: 0.8;
                gap: 5px;
            }
        }
    }
    .sharing-view-icon{
        fill: white;
    }
}
@media (max-width:500px)
{
    .sharing-details-phone-wrapper{
        width: 100% !important
    }
}