// forms ----------------------

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  color: $dark;
  font-size: 0.85rem;
  font-weight: 600 !important;
  margin-bottom: 12px;
  line-height: 1.25rem;
}

input,
.input,
textarea {
  border-radius: 10px;
  background: var(--10, #f4f4f4);
  border: 1px solid #f4f4f4;
  flex: 1;
  font-size: 14px;
  padding: 16px 20px;
  transition: box-shadow 0.2s ease;
  /* Add transition for box-shadow */
  font-weight: 500 !important;
  color: $dark !important;
}

input {
  height: 48px;
}

textarea {
  flex: none;
  min-height: 80px;
  max-height: 400px;
}

.input-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light;
  border-radius: 10px;
  padding: 26px;
  min-height: 48px;
}

input:focus,
textarea:focus {
  border: 1px solid $primary;
  background-color: $white;
  outline: none;
}

.input-group {
  width: 100%;
  border-radius: 10px;
}

.input-group-append {
  height: 48px;
  flex-shrink: 0;
  padding: 12px 20px;
  background: var(--10, #f4f4f4);
  border-radius: 0px 10px 10px 0px;
  align-items: center;
  justify-content: center;
}

.input-group {
  .input-group-append + input {
    border-right: 0 !important;
  }
}

.input-group .input-group-append {
  border-left: 0 !important;
}

// Form -switch

.add-page-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--30, #efefef);
  background: var(--0, #fff);
  padding: 10px 20px;
}

.form-switch {
  display: flex;
  align-items: center;
}

.form-switch .form-check-input {
  width: 48px;
  margin: 0;
  padding: 0 14px;
  height: 28px;
  background-color: #f4f4f4;
  border: none;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: $green !important;
  border-color: $green !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
}

.error-input {
  border: 1px solid $danger !important;
  background-color: $white !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
