.image-selector {
  display: flex;
  width: 100%;
  gap: 32px;
  align-items: center;
}
.image-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.Edit-black-icon-container {
  color: black !important;
  .Edit-blue-icon {
    fill: black;
  }
  &:hover {
    color: black;
    .Edit-blue-icon {
      fill: black;
      font-weight: 400;
    }
    .update-blue-icon {
      fill: black;
    }
  }
}

.Edit-blue-icon-container {
  color: #3d9be9 !important;
  .Edit-blue-icon {
    fill: #3d9be9;
  }
  &:hover {
    color: white;
    .Edit-blue-icon {
      fill: white;
      font-weight: 400;
    }
    .update-blue-icon {
      fill: white;
    }
  }
}

.delete-icon-conatiner {
  color: #be0f00 !important ;
  .delete-image-icon {
    fill: #be0f00;
  }
  &:hover {
    color: white;
    .delete-image-icon {
      fill: white;
      font-weight: 400;
    }
  }
}

.update-blue-icon {
  fill: #3d9be9;
  &:hover {
    fill: black;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .image-selector {
    transform: scale(0.85);
    transform-origin: left;
    img {
      transform: scaleY(0.9);
    }
  }
}
