.Main_Investor_Info_Container {
}
.Investor_DataBase_Container {
  padding: 1rem 3rem 0 0;
  width: 100%;

  :global(.searchWrap) {
    width: 100%;
  }
}

.headingWrap {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.roadSearchWrap {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.title,
.darktitle {
  border: 1px solid #3d9be9;
  border-radius: 30px;
  padding: 9px 14px 7px 14px;
  color: #3d9be9;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  cursor: pointer;
}

.darktitle {
  color: #1a1d1f;
  border: 1px solid #ced5de;
}

.companyName {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0;
  cursor: pointer;
}
.companyName:hover {
  text-decoration: underline;
  text-decoration-color: #3d9be9 !important;
}

.companyType {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #6f767e;
  padding-bottom: 5px;
}

.companyType_dark {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1a1d1f;
  padding-bottom: 5px;
}

.viewTitle {
  border: 1px solid #9a9fa5;
  border-radius: 30px;
  padding: 10px 29px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  width: 98px;
  cursor: pointer;
}

.investorDataWrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  padding-top: 16px;

  .investorInfoWrap {
    display: flex;
    align-items: start;
    gap: 1rem;
  }
}

.investorMapWrap {
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 22px;
}

.investorMapWrap > :first-child {
  padding-top: 0;
}

.investorButtons {
  display: flex;
  gap: 1rem;
}
.filterWrap {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  width: calc(100vw - 18rem);
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.multi_select_filter_wrapper {
  min-width: max-content;
}

.investortypetitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 7px;
  cursor: pointer;
}

/*Investor*/
.selected {
  color: red;
}

/*Locations*/

.InvestorFormHeader {
  font-family: Inter;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1a1d1f;
}

.formConatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .formInput {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 6px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #1a1d1f;
    }

    select {
      padding: 15px 10px;
      border-radius: 10px;
      border: none;
      background: #f4f4f4;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #6f767e;
    }
  }
}

.bottomContainer {
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
}

.customBtn {
  height: 40px !important;
  padding: 0 18px !important;
  font-size: 15px;
  font-weight: 500 !important;
}
.database_none_wrapper,
.phonedarktitle {
  display: none;
}
@media (max-width: 1235px) {
  .filterWrap {
    width: 100vw;
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  .Investor_DataBase_Container {
    width: 100vw;
    overflow: hidden !important;
  }
  .headingWrap {
    flex-direction: column !important;
    justify-content: unset !important;
    h3 {
      margin-bottom: 0 !important;
    }
    .title {
      display: none;
    }
    .searchWrap {
      width: 100%;
    }
  }
  .filterWrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding-left: 10px;
  }
  .headingWrap {
    padding-left: 5px;
  }
  .webdarktitle,
  .darktitle {
    display: none;
  }
  .phonedarktitle {
    display: block;
  }
  .heading_people_products_container {
    width: 100vw;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px !important;
  }
  .products_database_heading_container {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 480px) and (max-width: 900px) {
  .investorDataWrap {
    width: calc(50% - 1vw) !important;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .tabdarktitle.phonedarktitle {
    display: none !important;
  }
  .darktitle {
    display: block !important;
  }
  .roadSearchWrap {
    flex-direction: row-reverse !important;
    justify-content: space-between;
    width: 100vw !important;
    padding: 0 10px;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .investorMapWrap {
    width: 95vw;
    margin: 0 1vw 0 2.5vw;
    display: flex;
    flex-wrap: wrap;
    align-items: normal !important;
    justify-content: center;
  }
  .investorDataWrap {
    width: calc(100% - 1vw);
    flex-direction: column;
    overflow: hidden;
    justify-content: center !important;
    border-bottom: none !important;
    border: 1px solid #efefef !important;
    border-radius: 10px;
    padding: 10px !important;
    margin: 10px 1vw 10px 0px;
  }
  .investorInfoWrap {
    width: 100%;
    gap: 0 !important;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center !important;
  }
  .Product_database_heading_container {
    width: calc(100%) !important;
    gap: 0 !important;
  }
  .companyName {
    min-width: 100% !important;
  }
  .companyType {
    margin-bottom: 3px !important;
  }
  .investorButtons {
    margin-top: 10px;
    justify-content: center;
    button,
    div {
      scale: 0.8;
    }
  }
  .typediv_productDatabse {
    margin-bottom: 3px !important;
  }
  .mbres_none {
    display: none !important;
  }
  .database_none_wrapper {
    display: block !important;
  }
  .investor_inner_datawrap {
    padding: 10px !important;
    border: 1px solid #efefef !important;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1230px) {
  .Main_Investor_Info_Container {
    padding: 1rem 0 1rem 1rem;
  }
}
