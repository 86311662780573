.parent {
  display: flex;
}

.parent > :nth-child(1) {
  width: 65%;
}

.additional {
  padding: 1rem 2rem;
}

.parent > :nth-child(2) {
  width: 35%;
}

.person-info-container {
  padding: 20px;
  border: 1px solid #efefef;
  padding-top: 10px;
}

.social-channels {
  display: flex;
  gap: 1.25rem;
}
.work-icon-conatiner {
  width: 20px;
  height: 20px;
}

.people-overview-container {
  label {
    margin-bottom: 0 !important;
  }
}

.keylinkContainer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .keylink-card {
    display: flex;
    gap: 1.5rem;
    align-items: start;
    cursor: pointer;
    // flex-direction: column;

    .keylink-col {
      // margin-top: 10px;
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
    }

    .keylink-image {
      min-width: 8rem;
      border-radius: 10px;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      // flex: 1;
      flex-grow: 0;
    }

    .keyLink-image-size {
      height: 30px;
      width: 30px;
    }
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .parent {
    flex-direction: column;
    width: 100%;
    .person-info-container {
      width: 100%;
    }
    .additional {
      width: 100%;
    }
  }
  .about-profile-inputs {
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
  }
  .investement-mobile-responsive {
    padding: 1rem !important;
    padding-bottom: 0 !important;
  }
}
.companyNameArrow {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.rightUpArrow {
  svg {
    margin-bottom: 4px;
  }
}

.profile-info-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
}

@media (max-width: 768px) {
  .profile-info-wrapper {
    grid-template-columns: 1fr;
  }
}
