.card {
  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #fff;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  gap: 8px;
}

.card img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.profileName {
  color: var(--100, #1a1d1f);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0 0 0;
}

.view-profile-btn {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 200px) and (max-width: 480px) {
  .card {
    min-width: 55%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .card {
    min-width: 30%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1750px) {
  .card {
    padding: 15px 30px;
  }
}

@media (min-width: 1650px) and (max-width: 1749px) {
  .card {
    padding: 20px 30px;
    gap: 2px;
  }

  .view-profile-btn {
    padding: 5px 12px;
    height: 40px !important;
  }
}

@media (min-width: 1580px) and (max-width: 1650px) {
  .card {
    padding: 10px 20px;
    gap: 0px;
    max-height: 165px;
  }

  .view-profile-btn {
    padding: 5px 12px;
    height: 40px !important;
  }
}

@media (min-width: 1580px) and (max-width: 1650px) {
  .card {
    padding: 10px 20px;
    gap: 0px;
    max-height: 165px;
  }

  .view-profile-btn {
    padding: 5px 12px;
    height: 40px !important;
  }
}
