.dataroom-container {
  .dataroom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dataroom-item {
    display: flex;
    // align-items: ;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.7rem;
    // cursor: pointer;

    .item-detail {
      flex: 1;
    }
  }
}

.data-room-img {
  width: 9rem; /* 1rem is equivalent to the font-size of the root element (usually the <html> element) */
  height: 6.8rem;
  // overflow: hidden; /* Ensures the image doesn't overflow its parent */
  min-width: 9rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.video-div {
  height: 335px;
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-div iframe {
  width: 100%;
  height: 100%;
}
.upload-icon-container {
  fill: rgb(78, 86, 96);
}

#hidden-iframe {
  display: none;
}
.dataroom-right-side {
  width: min-content !important;
}

@media (min-width: 200px) and (max-width: 480px) {
  .dataroom-container {
    width: 100%;
    .dataroom-item {
      flex-direction: column;
      width: 100%;
      // .data-room-img {
      //   width: 100%;
      //   height: 12rem;
      // }
      padding: 10px;
      border: 1px solid #efefef;
      border-radius: 10px;
      .item-detail {
        width: calc(100% - 10rem);
      }
    }

    .dataroom-header {
      width: 100%;
      flex-wrap: wrap;
      gap: 15px;
      .filter-item-container {
        min-width: 100%;
        flex-wrap: nowrap;
        overflow: scroll;
      }
      .search {
        min-width: 100%;
      }
    }
  }

  .dataroom-left-side,
  .dataroom-right-side {
    display: flex;
    width: 100% !important;
    gap: 1rem;
  }
}

.dataroom-left-side,
.dataroom-right-side {
  display: flex;
  gap: 1rem;
}

.dataroom-right-side {
  align-items: center;
  justify-content: center;
}

@media (min-width: 480px) and (max-width: 900px) {
  .dataroom-item {
    flex-direction: row !important;
    width: 100%;
  }
  .dataroom-right-side {
    width: min-content !important;
  }
}
@media (min-width: 480px) and (max-width: 780px) {
  .dataroom-header {
    flex-direction: column !important;
    gap: 15px;
  }
}
@media (min-width: 780px) and (max-width: 900px) {
  .dataroom-header {
    flex-direction: row !important;
    width: 100%;
  }
}
// @media (min-width: 200px) and (max-width: 310px) {
//   .item-detail {
//     width: 35% !important;
//   }
// }

.audio-image-div {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 200px;
  height: 200px;
  overflow: hidden;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
