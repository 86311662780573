.radioContainer {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 5px;
  line-height: 1;
  user-select: none;
}

/* hide orig input */
.radioContainer input {
  position: absolute;
  opacity: 0;
}

.radioContainer .label {
  margin-left: 22px;
}

.radioContainer .radio {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #c0c4c9;
  border-radius: 50%;
}

.radioContainer input:checked ~ .radio {
  background-color: #3d9be9;
  border: 1px solid #3d9be9;
}

.radioContainer:focus .label {
  outline: red 10px solid;
}

/* center dot */

.radioContainer .radio::after {
  content: " ";

  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;

  display: none;
}

.radioContainer input:checked ~ .radio::after {
  display: block;
}
