.company_team {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 2rem;
  // img {
  //   border-radius: 50%;
  //   height: 60px !important;
  //   object-fit: cover;
  //   width: 60px !important;
  // }
}
.peopleWrap {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
