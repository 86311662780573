.footer {
  margin-top: 8rem; /* Push the footer to the bottom of the content */
  height: 80px;
  background-color: #fff;
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-bottom: 0;
}
