@import "../../../../assets/scss/variables.scss";

.lib_cat_div {
  width: 100%;
  height: auto;
  background-color: #effaff;
  border-radius: 20px;
  margin: 25px 0px 75px 0px;
  padding: 50px 100px 50px 50px;
  h1 {
    font-family: $plus_jakara_sans;
    font-weight: 800;
    font-size: 32px;
    line-height: 40.32px;
    margin-bottom: 40px;
  }
  .lib_cat_search_items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    button {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 20px 10px 20px;
      color: #4e5660;
      border-radius: 40px;
      border: 1px solid #ced5de;
      background: white;
      cursor: pointer;
    }
  }
}
