.Investor-DataBase-Container {
  padding: 1rem 3rem 3rem 0;
  width: 100%;
}

@media screen and (max-width: 1233px) {
  .Investor-DataBase-Container {
    padding: 1rem 3rem 2.5rem 3rem;
  }
}

@media screen and (max-width: 786px) {
  .Investor-DataBase-Container {
    padding: 1rem;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .Investor-DataBase-Container {
    /* width: 100%; */
    padding-left: 0;
    padding-right: 0;
    width: 100vw;
    overflow: hidden;
  }
  .Investor-DataBase-Heading {
    padding-left: 10px;
  }
  .investor-heading-conatiner {
    padding-left: 10px;
  }
}
