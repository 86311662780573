@import "../../../../assets/scss/variables.scss";

.Quick-search-Container {
  width: 100%;
  height: auto;
  background-color: #f4f4f4;
  border-radius: 20px;
  margin: 25px 0px 75px 0px;
  padding: 50px 100px 50px 50px;
  h1 {
    font-family: $plus_jakara_sans;
    font-weight: 800;
    font-size: 32px;
    line-height: 40.32px;
    margin-bottom: 40px;
  }
  .Quick-search-Items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    button {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 20px 10px 20px;
      border-radius: 40px;
      border: none;
      background: white;
    }
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .Quick-search-Container {
    width: 100vw !important;
    padding: 30px 20px !important;
    border-radius: 0px !important;
  }
}
