@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };

 .content-sharing-phone-container-sh{
    background: none;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    .sharing-details-phone-wrapper-sh{
        width: 46%;
        height: 240px;
        background: none;
        border:1px solid  #eee;
        border-radius: 10px;
        margin: 10px;
        overflow: hidden;
        .sharing-details-content-conatiner-sh{
            height: 165px;
            width: 90%;
            border: 1px solid #eee;
            border-radius: 10px;
            background: none;
            margin: 15px;   
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
            overflow: hidden;
            margin-bottom: 5px;
            .detail-contet-item-phone-sh{
                margin: 10px;
                background: none;
                :first-child
                {
                    width: 70%;
                    height: 10px;
                    margin-bottom: 10px;
                }
                :last-child{
                    width: 100%;
                    height: 15px;
                }
            }
        }
        .sharing-details-content-database-conatiner-sh{
            height: 170px;
            width: 90%;
            border: 1px solid #eee;
            border-radius: 10px;
            background: none;
            margin: 15px;   
            overflow: hidden;
            margin-bottom: 5px;
            .image-database-sh-conatiner{
                display: flex;
                gap: 10px;
                align-items: center;
                width: 100%;
                background: none;
                margin: 10px;
                .image-sh-container{
                    height: 60px;
                    width: 20%;
                    border-radius: 10px;
                }
                .image-heading-sh-conatiner{
                    width: calc(70% - 10px );
                    height: 30px;
                }
            }
            .content-database-sh-item-lg{
                width: 70%;
                height: 20px;
                margin: 10px;
            }
            .content-database-sh-item-md{
                width: 50%;
                height: 20px;
                margin: 10px;
            }
        }
    }
    .sharing-details-phone-btns-sh{
        background: none;
        display: flex;
        justify-content: center;
        gap: 10px;
        div{
            width: 30%;
            scale: 0.8;
        }
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
}

 @media (min-width:200px) and (max-width:480px){
    .sharing-details-phone-wrapper-sh{
        width: calc( 100vw - 10px ) !important;
    }
 }