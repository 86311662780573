
/* CSS for various margin-top classes in rem units */
.mt-xs{
  margin-top: 0.25rem;
}


.mt-small {
    margin-top: 1rem;
}

.mt-medium {
  margin-top: 1.56rem;
}



.mt-large {
  margin-top: 2rem;
}

.mt-xl {
  margin-top: 3rem;
}

.mt-xxl{
  margin-top: 4rem;
}

.mb-xs{
  margin-bottom: 0.25rem;
}

.mb-small {
    margin-bottom: 1rem;
}

.mb-sm{
  margin-bottom: 1.31rem !important;

}

.mb-m{
  margin-bottom: 1.25rem;
}
.mb-medium{
    margin-bottom: 1.56rem;
}

.mb-large{
    margin-bottom: 2rem;
}

.mb-xl{
  margin-bottom: 3rem;
}
// paddings

/* CSS for various padding-top classes in rem units */
.pt-small {
  padding-top: 1rem;
}

.pt-medium {
  padding-top: 1.56rem;
}

.pt-large {
  padding-top: 2rem;
}

/* CSS for various padding-right classes in rem units */
.pr-small {
  padding-right: 1rem;
}

.pr-medium {
  padding-right: 1.56rem;
}

.pr-large {
  padding-right: 2rem;
}

/* CSS for various padding-bottom classes in rem units */
.pb-small {
  padding-bottom: 1rem;
}

.pb-medium {
  padding-bottom: 1.56rem;
}

.pb-large {
  padding-bottom: 2rem;
}

/* CSS for various padding-left classes in rem units */
.pl-small {
  padding-left: 1rem;
}

.pl-medium {
  padding-left: 1.56rem;
}

.pl-large {
  padding-left: 2rem;
}
