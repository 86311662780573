@import "../../../assets/scss/variables.scss";
.sidebar {
  width: 18rem !important;
  min-width: 18rem !important;
  padding: 2.5rem;
  padding-top: 1rem;
  padding-right: 1.87rem;
  display: flex;
  flex-direction: column;
  transition: width 0.6s ease;
  gap: 10px;
  text-decoration: none; // Remove underlines from the sidebar
  .active-side {
    color: $dark !important;
    background: #f4f4f4;
    border-radius: 70px;
  }
  .sidebar-link {
    path {
      fill: $dark;
    }
  }

  .ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 4px;
      padding: 11px 16px;

      a {
        text-decoration: none;
        color: $dark; // Set the text color for links
        transition: color 0.3s ease; // Smooth transition for color change
        padding-left: 10px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          display: flex;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          font-size: 18px;
          line-height: normal;
        }
        &:hover {
          color: $primary;
          path {
            fill: $primary;
            transition: 0.3s ease;
          }
        }
      }
      .sidebar-active-link {
        &:hover {
          path {
            fill: $dark;
          }
        }
      }
    }
  }
}

.sidebar-active-link {
  color: $dark !important;
  path {
    fill: $dark;
  }
}

@media screen and (max-width: 1233px) {
  .sidebar {
    width: 12.5rem !important;
    min-width: 12.5rem !important;
    padding: 1rem 0rem 1rem 0rem;
    display: flex;
    flex-direction: column;
    transition: width 0.6s ease;
    gap: 10px;
    text-decoration: none;
    height: max-content;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.125);
    border-radius: 16px;
    position: fixed;
    z-index: 1800;
    top: 80px;
    left: 10px;
    .active-side {
      background-color: #f7f7f7;
      a {
        color: $dark !important;
        i {
          color: $dark !important;
        }
      }
      border-radius: 0;
    }

    .ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        margin: 0px !important;
        // &:hover {
        //   background-color: #F7F7F7;
        // }
        a {
          text-decoration: none;
          color: $dark;
          transition: color 0.3s ease;
          font-size: 15px;
          display: flex !important;
          line-height: 20px;
          border-radius: 0;
          margin-bottom: 0;

          i {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            font-style: normal;
          }
          &:hover {
            path {
              fill: $primary;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.grey-fill-saved {
  fill: #4e5660;
}
