@import '../../../../../assets/scss/style.scss';

$shimmerColor: rgba(0, 0, 0, 0.05); // Adjust the shimmer color as needed

@keyframes shimmerAnimation {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

.navbar-container-shimmer {
  border-bottom: 1px solid $light;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 3.75rem; /* 10px 60px converted to rem */
  height: 4.5rem; /* 72px converted to rem */
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 20%, $shimmerColor 40%, transparent 60%);
    animation: Shimmer 1.5s infinite;
  }

  .navbar-container-shimmer-1 {
    width: 200px;
    height: 80%;
    background: linear-gradient(to right, transparent 20%, $shimmerColor 40%, transparent 60%);
    background: $shimmerColor;
  }

  .navbar-container-shimmer-2 {
    display: flex;
    gap: 15px;
    height: 80%;

    .navbar-container-shimmer-2-1,
    .navbar-container-shimmer-2-2 {
      width: 30px;
      height: 80%;
      border-radius: 50%;
      background: $shimmerColor;
    }
  }
}


.Home-shimmer-container{
    width: calc( 100vw - 18rem);
    height: calc(  100vh - 4.5rem);
    display: flex;
    .side-shimmer-container{
        width: 20%;
        padding: 1rem;
        height: 100%;
        border-right:1px solid $light;
        .item-shimmer-container{
            width: 100%;
            background: $shimmerColor;
            margin-bottom: 15px;
            height: 30px;
            background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
            animation: shimmer 1s infinite linear;
            background-size: 300%;
            background-position-x: 100%;
        }
    }
    .Home-page-shimmer-conatiner{
        width: 75%;
        height: 100%;
        .item-shimmer-container-cover{
            width: 100%;
            height: 45%;
            background: $shimmerColor;
            background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
            animation: shimmer 1s infinite linear;
            background-size: 300%;
            background-position-x: 100%;
        }
    }
    .address-shimmer-container{
        width: 25%;
        height: 100%;
        padding: 1rem;
        border-left:1px solid $light;
    }
}
.item-shimmer-container{
    width: 100%;
    background: $shimmerColor;
    margin-bottom: 15px;
    height: 30px;

}
.item-shimmer-medium-container{
    width: 100%;
    background: $shimmerColor;
    margin-bottom: 15px;
    height: 15%;
}
.item-shimmer-container-h{
    width: 100px;
    background: $shimmerColor;
    margin-bottom: 15px;
    height: 20px;

}
.item-shimmer-container-lh{
    width: 160px;
    background: $shimmerColor;
    margin-bottom: 15px;
    height: 30px;
}
.item-shimmer-container-logo{
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: 22px;
}
.tabstrip-shimmer-container{
    margin-left: 20px;
    width: calc(100% - 20px);
    border-bottom:1px solid $light;
}
.item-shimmer-container-content{
    width: calc(100% - 1rem);
    margin: 1rem;
    height: 30%;
    background: $shimmerColor;
}
@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
 .shimmer-wrapper-conatiner{
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 1000000;
.item-shimmer-container,.item-shimmer-container-cover,.item-shimmer-container-content,.item-shimmer-container-h,.item-shimmer-container-lh,.navbar-container-shimmer-2-1,.navbar-container-shimmer-2-2,.navbar-container-shimmer-1,.item-shimmer-medium-container{
   background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
   animation: shimmer 1s infinite linear;
   background-size: 300%;
   background-position-x: 100%;
}}
.side-shimmer-container{
    .item-shimmer-container{
   background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
   animation: shimmer 1s infinite linear;
   background-size: 300%;
   background-position-x: 100%;
    }
}


@media (min-width: 200px) and (max-width:900px) {
  .shimmer-wrapper-conatiner{
    width: 100vw;
  }
  .Home-shimmer-container{
    width: 100vw;
    height: 1500px !important;
    flex-direction: column;
    .Home-page-shimmer-conatiner{
      width: 100vw;
      .item-shimmer-container-cover{
        height: 280px;
      }
      .item-shimmer-container-content{
        height: 300px;
      }
    }
    .address-shimmer-container{
      width: 100vw;
    }
  }
}