.crausel-arrow-right{
    border-radius: 16px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    cursor: pointer;
}
.diable-arrow-carusel{
    border-radius: 16px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff; /* Medium gray */
    border: 1px solid #dfdfdf;
    cursor: pointer;
    user-select: none;
    path{
        stroke: #e9ecef;
    }
}

@media (min-width:200px) and (max-width:520px){
    .carusel-atom-container{
        display: none !important;
    }
}