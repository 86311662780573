.page_card{
    width: 100%;
    height: auto;
    border-radius: 12px;
    border-bottom: 1px solid var(--30, #EFEFEF);
    background-color: #fff;
    padding: 32px;
}

@media screen and (max-width : 400px) {
    .page_card {
        padding: 1.25rem;
    }
}