@import "../../../assets/scss/style.scss";
.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion {
  background-color: inherit;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid #ced5de;
  border-radius: 0;
}

.accordion .accordion-button:not(.collapsed) {
  color: $dark !important;
  box-shadow: none !important;
  background: transparent;
}

.control-image-accrordion::after {
  content: "";
  display: table;
  clear: both;
}

.control-image-accrordion {
  img {
    display: block;
    width: 40vw;
    max-width: 100%;
  }

  // .ql-align-right {
  //   width: 100%;
  //   display: flex;
  //   justify-content: end;
  //   img {
  //     margin: 8px 0 8px 8px !important;
  //   }
  // }

  // .ql-align-left {
  //   width: 100%;
  //   display: flex;
  //   justify-content: end;
  //   img {
  //     float: start;
  //     margin: 0 8px 8px 8px;
  //   }
  // }

  // .ql-align-center {
  //   img {
  //     display: block;
  //     margin: 8px auto !important;
  //   }
  // }

  .ql-align-right {
    img {
      float: right;
      margin: 8px 0 8px 8px !important;
    }
  }

  .ql-align-left {
    img {
      float: left;
      margin: 0 8px 8px 8px;
    }
  }

  .ql-align-center {
    img {
      display: block;
      margin: 8px auto !important;
    }
  }
}
@media (min-width: 200px) and (max-width: 480px) {
  .control-image-accrordion {
    img {
      width: 100vw !important;
      max-width: 100%;
      // margin: 0 !important;
    }
  }
}
@media (min-width: 480px) and (max-width: 900px) {
  // .control-image-accrordion {
  //   img {
  //     margin: 0 19.5vw !important;
  //   }
  // }
}

#faq-rendering {
  .accordion-header {
  }
}
