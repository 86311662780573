.sort-func-conatiner {
  .positionStyles {
    position: absolute;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 16px;
    padding: 0.5rem 0;
    z-index: 1;
    width: 250px;
    margin-top: 10px;
    min-width: 200px;
    max-width: 200px;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none; /* Remove default bullet points */
    padding: 0; /* Remove default padding */
    margin-bottom: 0;
  }

  li {
    padding: 8px 10px; /* Add padding for each list item */
    cursor: pointer; /* Change cursor to pointer on hover */
    display: flex; /* Flex layout for dot and text */
    align-items: center; /* Center vertically */
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected {
    font-weight: 600; /* Make selected item bold */
    background-color: #f4f4f4; /* Highlight selected item background */
  }

  .dot {
    font-size: 12px; /* Size of the dot */
    color: #007bff; /* Dot color */
    margin-right: 5px; /* Space between dot and text */
  }
}
