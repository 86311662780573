.description {
  color: var(--100, #1a1d1f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
}

.form-group {
  a {
    padding: 0;
    font-size: 14px;
  }
}

.video-div {
  height: 335px;
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-div iframe {
  width: 100%;
  height: 100%;
}

#hidden-iframe {
  display: none;
}

.tag-wrapper {
  display: flex !important;
  padding: 6px 12px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 50px !important;
  background: #f4f4f4 !important;
}

// Share content css

.inputFiledWrap {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 0 10px;
  margin-top: 1rem;
}

.daysInput {
  border: 0px !important;
  height: 35px;
  width: 4rem;
  background-color: #fff;
  border-color: #efefef;
  padding: 0;
}

.shareLinkWrap {
  .shareLink {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    // gap: 1rem;
  }

  .subShareLink {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .linkAndIcon {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .copylink {
    cursor: pointer;
  }

  .inputFiled {
    input {
      // width: 90px;
    }
  }

  .errorLabel {
    color: red;
  }

  .genratedButton {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.shareLinkEmail {
  .titleWrap {
    // padding-top: 10px;
    padding-bottom: 10px;
  }

  .title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6f767e;
  }

  .formStyle {
    // padding-top: 10px;

    label {
      margin: 12px 0px;
    }
  }

  .email {
    display: flex;
    flex-direction: column;
  }
  .linkAndIcon {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .copylink {
    cursor: pointer;
  }

  .titleButton {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
  }

  .footertitle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.generate-link-container {
  width: 100%;
  display: flex;
  gap: 1%;
  margin-bottom: 15px;
  margin-top: 1rem;
  button {
    width: 20%;
  }
  .linkAndIcon {
    width: 77%;
    margin-bottom: 0;
    label {
      max-height: 20px;
    }
  }
}
.subShareLink-container {
  align-items: center;
}
.edit-video-icon,
.video-delete-icon {
  fill: #4e5660;
}
