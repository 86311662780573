/* Loader.css */
.upload-loader-container {
  position: fixed;
  bottom: 20px;
  right: 20px; /* Adjusted to keep the container 10px from the right */
  /* display: flex;
  align-items: center; */
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999; /* Ensure it appears above other content */
  min-width: 300px;
}

.upload-loader {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.uploading-info {
  display: flex;
  justify-content: space-between;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
