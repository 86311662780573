@import "../../../assets/scss/variables.scss";

.edit-block-icon {
  font-size: 20px;
  background: #3d9be91a;
  padding: 12px 20px 12px 20px;
  border-radius: 30px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #3d9be9;
  }
}
.block-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 10px;
  // text-align: center;
  justify-content: center;
  gap: 20px;
}
.edit-container-desciption {
  font-family: $inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
}
.edit-container-btn {
  font-family: $inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  width: 120px;
  height: 48px;
  top: 386px;
  left: 127px;
  border-radius: 24px;
  border: 1px solid grey;
}
.edit-container-heading {
  font-family: $inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
}
.block-edit-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
