.team-card-container-wrapper {
  border-radius: 12px;
  overflow: hidden;
}

.team-card-container {
  padding-bottom: 0 !important;
  max-height: 400px;
  overflow-x: auto;
}

.team-people {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.team-image {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}
