.blog-card {
  .blog-image {
    height: 240px;
    border-radius: 16px;
    border: 1px solid #f4f4f4;
    margin-bottom: 24px;
    position: relative;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.font-20 {
  font-size: 20px;
}

.color-black {
  fill: black;
}

.color-grey {
  background-color: #efefef;
}

.text-color {
  color: #4e5660;
  size: 14px;
  font-weight: 400;
}

.catregory-name-pic-container {
  position: absolute;
  top: 10px;
  left: 10px;
  border: 0.5px solid #6f767e;
  background: white;
  color: black;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}

.blog-card-btns {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.category-status-div {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.extra-card-blog-styles {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    font-size: 14px !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bolder;
  }
}

@media (min-width: 200px) and (max-width: 765px) {
  .blog-card {
    width: 100%;
    .blog-image {
      width: 100%;
    }
  }
}
