.edit-screen {
  background-color: #ffffff;
  // margin: -32px -60px;
  height: calc(100vh - 4.5rem);
  display: flex;

  .edit-navigation {
    padding: 40px;
    width: 22%;

    // border-right: 1px solid #CED5DE;

    ul {
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      color: #1a1d1f;
      padding-left: 10px;

      i {
        font-size: 18px;
        line-height: normal;
      }

      .logout {
        color: #be0f00;
      }

      li {
        display: flex;
        gap: 10px;
        cursor: pointer;
        padding: 11px 16px;
        margin-bottom: 8px;
      }

      .li-active {
        background-color: #f4f4f4 !important;
        border-radius: 60px;
        max-width: 250px;
      }
    }
  }

  .edit-content {
    width: 78%;
    padding: 2.5rem 3.5rem;
    border-left: 1px solid #ced5de;
    /* height: max-content; */
    // height: min-content;
    max-height: calc(100vh - 4.5rem);
    overflow: scroll;

    .edit-child {
      width: 82%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .edit-screen {
    .edit-navigation {
      display: none;
    }

    .edit-content {
      width: 100% !important;
    }
  }
}

.edit-description {
  font-size: 16px;
  color: #6f767e;
  font-weight: 500;
}

.phn-row {
  display: flex;
  gap: 10px;
}

.edit-password {
  display: flex;
  gap: 1rem;
}

.edit-profile-div,
.edit-change-pass {
  width: 60%;
}

.edit-validator {
  padding: 3.5rem;
  padding-top: 0;
}

@media screen and (max-width: 800px) {
  .edit-password {
    flex-direction: column;
  }

  .edit-profile-div {
    width: 100%;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .edit-password {
    width: 100%;
  }

  .edit-content {
    padding: 1.5rem !important;
  }

  .edit-change-pass {
    width: 100%;

    form {
      width: 100%;
    }
  }

  .edit-validator {
    padding: 0 !important;
  }
}
