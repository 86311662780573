.pass_screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;

  .pass_content {
    max-width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .pass_screen {
    min-height: 40vh;
  }

  .pass_content {
    max-width: 60% !important;
  }
}

@media screen and (max-width: 500px) {
  .pass_screen {
    min-height: 60vh;
  }

  .pass_content {
    max-width: 80% !important;
    // margin-bottom: 3rem;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .pass_screen {
    align-items: start;
    // justify-content: start;
    margin: 2rem 0.25rem;
  }
}
