.data-container {
  display: flex;
  gap: 3rem;

  .pro-info-heading {
    margin-bottom: 1rem;
    margin-top: 10px;
  }
  .pro-info-data {
    margin-bottom: 2.5rem;
  }
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;

  // border-bottom: 1px solid #efefef !important;

  th,
  td {
    // padding: 8px;
    color: var(--100, #1a1d1f);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 228.571% */
    letter-spacing: -0.14px;
    text-align: left;
    padding-left: 0;
    border-top: none;
    border-bottom: none;
  }

  td {
    color: #1a1d1f;
    width: 50%;
  }

  th {
    color: #6f767e;
  }
}
@media (min-width: 1900px) and (max-width: 2300px) {
  .info-table {
    th {
      width: 30%;
    }
  }
}
@media (min-width: 2300px) and (max-width: 7000px) {
  .info-table {
    th {
      width: 10%;
    }
  }
}

// Video
.video-container {
  .video-viewer {
    display: flex;
    border: 1px solid #efefef;
    border-radius: 20px;
    width: 100%;

    .video-div {
      margin-top: 0 !important;
      height: 420px;
      border-radius: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      // margin-bottom: 10px;
    }

    .video-div iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #hidden-iframe {
      display: none;
    }

    .video-data {
      flex: 1;
      border-left: 1px solid #efefef;

      .header {
        padding: 20px;
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      // .header:first-child {
      //   margin-bottom: 19px !important;
      // }

      .video-item {
        border-bottom: 1px solid #efefef;
        // border-top: 1px solid #efefef;
        padding: 10px 20px;
        display: flex;

        align-items: center;
        gap: 5px;
        .video-time-div {
          display: flex;
          align-items: center;
          gap: 8px;

          i {
            width: 14px;
            height: 14px;
            line-height: normal;
          }
        }
      }

      .active-video {
        background-color: #f4f4f4;
      }
    }
  }

  .video-info {
    margin-top: 2rem;
  }
}

.product-overview-container {
  width: 100%;
}

// Data Room

@media (min-width: 200px) and (max-width: 900px) {
  .Disclaimer-Heading {
    margin-bottom: 0 !important;
  }
  .data-container {
    flex-direction: column;
  }
  .Disclaimer-product-container {
    padding: 0px 15px 15px 15px;
  }
  .product-overview-container {
    padding: 0px 10px 10px 10px;
  }
  .video-viewer {
    flex-direction: column;
    // .video-div {
    //   margin-top: 20px;
    // }
  }
  .info-table {
    width: 100%;
    tbody {
      width: 100%;
      tr {
        width: 100%;
        th {
          width: 50%;
        }
        td {
          padding-left: 10%;
          width: 40%;
        }
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  .responsive-video-div {
    height: 200px !important;
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .responsive-video-div {
    height: 400px !important;
  }
}
// @media (min-width: 1290px) and (max-width:1460px) {
//   .video-container {
//     .video-viewer {

//       .video-div {
//         height: 81vh;
//         max-height: 81vh;
//       }
// }
//   }}

//   @media (min-width: 1460px) and (max-width:1610px) {
//     .video-container {
//       .video-viewer {

//         .video-div {
//           height: 85vh;
//           max-height: 85vh;
//         }
//   }
//     }}
//     @media (min-width: 1620px) and (max-width:1715px) {
//       .video-container {
//         .video-viewer {

//           .video-div {
//             height: 87vh;
//             max-height: 87vh;
//           }
//     }
//       }}

// @media (min-width: 1720px) and (max-width:1920px) {
//   .video-container {
//     .video-viewer {

//       .video-div {
//         height: 89vh;
//         max-height: 89vh;
//       }
// }
//   }}
