.circle_avatar{
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 50%;
    color: #374957;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.circle_avatar > span{ 
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px; /* 200% */
}





