@import "../../../../assets/scss/variables.scss";

.ImageContent-Container {
  width: 100%;
  .Investor-DataBase-Content-Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 35px;
    // flex-wrap: wrap;
    flex-direction: column;
    gap: 3px;
    .Investor-DataBase-Content-Heading-container {
      .Investor-DataBase-Content-Heading {
        color: #1e273b;
        font-family: $plus_jakara_sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
      .Investor-DataBase-Content-Information {
        color: var(--80, #6f767e);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
      }
    }
    .Investor-DataBase-Content-Header-btn {
      color: var(--0, #fff);
      text-align: center;
      font-family: $inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      display: inline-flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 40px;
      background: #3d9be9;
      border: none;
      height: max-content;
      margin-top: 10px;
    }
  }
  .Image-Content-Container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .Image-Content-Container-Pic {
      width: 30%;
      height: 290px;
      flex-shrink: 0;
      border-radius: 20px;
      background: #d9d9d9;
      background-repeat: no-repeat;
    }
    .Image-Content-Container-Information {
      width: 68%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      h1 {
        color: #1e273b;
        font-family: $plus_jakara_sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
      span {
        color: var(--80, #6f767e);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
      }
      button {
        width: max-content;
        color: var(--100, #1a1d1f);
        text-align: center;
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        border: 1px solid var(--50, #c0c4c9);
        border: none;
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .ImageContent-Container {
    width: 100vw;
    padding: 0px 10px;
  }
  .Image-Content-Container-Pic {
    width: 94vw !important;
  }
  .Image-Content-Container-Information {
    width: 94vw !important;
    h1 {
      margin-bottom: 0;
      margin-top: 20px;
    }
    .Information-content-2 {
      margin-bottom: 20px;
    }
  }
  .Image-Content-Container {
    flex-direction: row !important;
  }
  .Investor-DataBase-Content-Header-btn {
    margin-top: 20px;
    padding: 10px 32px !important;
  }
}
