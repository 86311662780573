@import "../../../../assets/scss/variables.scss";
.Investor-Latest-Added-Container {
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  .Investor-Latest-Added-Heading {
    color: #1e273b;
    font-family: $plus_jakara_sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .Latest-Added-Container {
    display: flex;
    gap: 1.99%;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
  }
  .Investor-Latest-Added-Heading {
    color: #1e273b;
    font-family: $plus_jakara_sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    overflow: hidden;
  }
  .Latest-Added-Container {
    display: flex;
    gap: 1.99%;
    align-items: center;
    width: 100%;
    padding-right: 15px;

    .Latest-added-company-details-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 23%;
      height: 178px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid var(--30, #efefef);
      background: var(--0, #fff);
      img {
        width: 100px;
        aspect-ratio: 3/2;
        object-fit: contain;
      }
      .Latest-added-company-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
      .Latest-added-company-Heading {
        color: var(--100, #1a1d1f);
        text-align: center;
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
        letter-spacing: -0.16px;
      }
      .Latest-added-company-endowment {
        color: var(--80, #6f767e);
        text-align: center;
        font-family: $inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.14px;
      }
    }
    .Latest-added-company-endowment {
      color: var(--80, #6f767e);
      text-align: center;
      font-family: $inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
    }
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .Latest-Added-Container {
    overflow-x: scroll !important;
    width: 100vw !important;
    padding-right: 30px;
  }

  .Latest-Added-Container:last-child {
    margin-right: 10px !important;
  }
  .Investor-Latest-Added-Container {
    padding: 40px 0px 40px 10px;
  }
  .Latest-added-company-details-container {
    width: 200px !important;
    margin-left: 30px;
  }
}
