.formConatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .formInput {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 6px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #1a1d1f;
    }

    select {
      padding: 15px 10px;
      border-radius: 10px;
      border: none;
      background: #f4f4f4;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #6f767e;
    }
  }
}
