@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
.shimmerSaved-container {
  width: calc(100vw - 18rem);
  height: 100vh;
  padding: 1rem 0;
  .shsd-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background: none;
    .shsd-heading {
      width: 250px;
      height: 100%;
    }
    .shsd-search {
      width: 400px;
      height: 100%;
      border-radius: 20px;
    }
  }
  .shsd-tabstrip {
    width: 100%;
    background: none;
    display: flex;
    gap: 15px;
    margin-top: 20px;
    padding-bottom: 10px;
    .shsd-tab-item {
      width: 10%;
      height: 40px;
    }
  }
  .shsd-cards-conatiner {
    width: 100%;
    display: flex;
    background: none;
    justify-content: space-between;
    padding: 1rem;
    padding-left: 0px;
    padding-right: 120px;
    align-items: center;
    .shsd-card-header {
      display: flex;
      gap: 15px;
      background: none;
      .shsd-card-img {
        width: 90px;
        height: 90px;
        border-radius: 15px;
      }
      .shsd-card-details {
        background: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .shsd-card-name {
          width: 180px;
          height: 15px;
          border-radius: 10px;
        }
        .shsd-card-loaction {
          width: 130px;
          height: 15px;
          border-radius: 10px;
        }
        .shsd-card-detals {
          width: 150px;
          height: 15px;
          border-radius: 10px;
        }
      }
    }
    .shsd-card-btn {
      width: 120px;
      height: 48px;
      border-radius: 24px;
    }
  }
  .shsd-border-line {
    width: 100%;
    height: 1px;
  }

  .shsd-cards-filter {
    height: 60px;
    border-radius: 24px;
    margin: 20px 0;
    margin-right: 64px;
  }
  div {
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    animation: shimmer 1s infinite linear;
    background-size: 300%;
    background-position-x: 100%;
  }
}
