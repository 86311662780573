.ql-toolbar.ql-snow {
  border: 1px solid #f4f4f4 !important;
  border-radius: 12px 12px 0 0 !important;
  background-color: #f4f4f4 !important;
}

.ql-container.ql-snow {
  border-radius: 0 0 12px 12px !important;
  border: 1px solid #f4f4f4;

  font-size: 14px;
}

#react-custom-quill {
  .ql-container.ql-snow {
    overflow-y: auto;
    resize: vertical !important;
  }

  .ql-container {
    min-height: calc(
      var(--editor-height) * var(--editor-percentage)
    ) !important;
  }
}
