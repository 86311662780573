// Date Picker 
.MuiInputBase-root,
.MuiOutlinedInput-root {
  background-color: #f4f4f4 !important;
  height: 48px;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  color: #1A1D1F;
}

.calender-input-component {
  display: contents;
}

.calender-input-component:focus-within {
  background-color: white;
  border: 1px solid #3D9BD9;
  outline: none;
  display: grid;
  overflow: hidden;
  border-radius: 10px;

  input {
    &:focus {
      border: none;
    }
  }

  div {
    background: white !important;
  }
}

.calender-input-component {
  input {
    font-weight: 500 !important;
    color: #1A1D1F !important;
  }

  button {
    color: black;
    width: 45px !important;
  }

  :first-child {
    width: 100%;
  }
}