.edit-container {
    padding-top: 2rem;
    padding-right: 2rem;

    .edit-page-display {
        display: flex;
        gap: 3rem;

        .left-side {
            width: 68.5%;
        }

        .right-side {
            width: 30%;
        }

    }
}

.radio-btn-input {
    width: 19px;
}