/* Set content for font-families */

.ql-lineHeight {
  .active {
    color: #06c;
  }
}

/* Line heights */
.ql-lineHeight {
  width: 65px;
  .ql-picker-label > svg {
    display: none;
  }
  /* .ql-picker-label::before{
      content: attr(data-label);
  } */

  .ql-picker-item::before {
    content: attr(data-label);
  }
}

.ql-snow .ql-editor blockquote {
  border-left-color: #3d9be9 !important;
}

.ql-editor hr {
  border-top-color: #969696 !important;
  margin: 15px 0;
}

.lineHeightSvg {
  margin-left: -44px;
}

.line-height-normal {
  line-height: normal;
}
.line-height-tight {
  line-height: 1;
}

.line-height-normal-tight,
.line-height-normalTight {
  line-height: 1.5;
}

.line-height-comfortable {
  line-height: 2;
}
.line-height-spacious {
  line-height: 2.5;
}

.line-height-extra-spacious,
.line-height-extraSpacious {
  line-height: 3;
}

/* Set content for sizes */
.ql-size-extra-small {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}

/* Custom fonts --------- */
.ql-size-12px {
  font-size: 12px !important;
}

.ql-size-13px {
  font-size: 12px !important;
}

.ql-size-14px {
  font-size: 14px !important;
}

.ql-size-16px {
  font-size: 16px !important;
}

.ql-size-18px {
  font-size: 18px !important;
}

.ql-size-20px {
  font-size: 20px !important;
}

.ql-size-25px {
  font-size: 25px !important;
}

.ql-size-30px {
  font-size: 30px !important;
}

.ql-size-35px {
  font-size: 35px !important;
}

.ql-size-38px {
  font-size: 38px !important;
}

/* ------------------------ */

.ql-editor {
  /* min-height: 45vh; */
  font-size: 16px;

  em {
    font-style: italic !important;
  }

  /* overflow-y: scroll;
  resize: vertical; */
}

.ql-container.ql-snow {
  border-radius: 0 0 10px 10px;
  /* background-color: #F4F4F4; */
  border: 1px solid #f4f4f4;
  font-family: Inter, sans-serif !important;
}
.App .ql-snow .ql-tooltip.ql-flip {
  width: auto;
  left: 17px !important;
  white-space: normal;
}

.ql-editor img {
  display: block !important;
  margin: 8px auto !important;
  /* width: 40rem; */
}

.ql-editor .ql-video {
  display: block;
  margin: auto;
  height: 20rem;
  width: 40rem;
}

.ql-toolbar.ql-snow {
  border: 1px solid #f4f4f4;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  border-bottom: 0;
}

@page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }
  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }
  .container .ql-toolbar.ql-snow {
    display: none;
  }
}

.richTextEditor {
  /* height: 300px; */
  height: 88vh;
  overflow-y: auto;
  .ql-editor {
    min-height: 250px !important;
  }
}

.richTextEditor::-webkit-scrollbar {
  width: 5px !important;
}

.fixed-position-styles {
  position: sticky;
  top: 72px;
  z-index: 1;
}
