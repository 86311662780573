@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
 .srbl-container{
    width:calc(99.5vw - 45rem);
    height: 100vh;
    margin: 1rem;
    padding-top: 2rem;
    .srbl-blog-back{
        width: 220px;
        height: 40px;
        border-radius: 20px;

    }
    .srbl-blog-heading{
        width: 250px;
        height: 40px;
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .srbl-blog-header-content{
        display: flex;
        gap: 10px;
        background: none;
        margin-bottom: 20px;
        .srbl-blog-description{
            width: 180px;
            height: 20px;
        }
    }
    .srbl-blog-image{
        width: 100%;
        height: 50vh;
        border-radius: 20px;
    }
    .srbl-blog-content{
        width: 100%;
        height: 20px;
        margin: 10px 0px;
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
 }
 .srbl-container-right{
    width: 25rem;
    height: 100vh;
    background: none;
    .srbl-blog-content{
        width: 25rem;
        height: 40px;
        margin: 10px 0px;
    }
    .srbl-blog-content-mini{
        margin-bottom: 20px;
        .srbl-blog-content-1{
            width: 24rem;
            height: 20px;
            margin: 10px 0px;
        }
        .srbl-blog-content-2{
            width: 18rem;
            height: 20px;
            margin: 10px 0px;
        }
        .srbl-blog-content-3{
            width: 20rem;
            height: 20px;
            margin: 10px 0px;
        }
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
}
@media (min-width:200px) and (max-width:480px){
 .srbl-container{
    width: 93.6vw !important;
 }
}
@media (min-width:480px) and (max-width:1200px){
    .srbl-container{
       width: 96vw !important;
    }
   }

   @media (min-width:200px) and (max-width:900px){
    .srbl-main-container{
      flex-direction: column;
    }
    .srbl-container{
      min-width: 96vw;
      height: 680px;
    }
    .srbl-container-right{
        display: none;
    }
  }