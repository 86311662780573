@import "../../../../assets/scss/variables.scss";

.parent {
  display: flex;
}

.parent > :nth-child(1) {
  width: 65%;
}

.parent > :nth-child(2) {
  width: 35%;
}

.margin {
  margin-top: 60px;
}

.company-info-container {
  border: 1px solid #efefef;
}
.detail-container {
  padding: 0 35px 10px 35px !important;
}

.detail-address {
  color: #6f767e;
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.14px;
}

.additional {
  padding: 1rem 2rem;
}
.address-card {
  div {
    align-items: start !important;
    div {
      align-items: center !important;
    }
  }
}
.address-icon-center {
  align-items: center !important;
}
@media (min-width: 200px) and (max-width: 900px) {
  .parent {
    width: 100%;
    flex-direction: column;
  }
  .company-info-container {
    width: 100% !important;
  }
  .detail-container {
    padding-left: 15px;
    padding-right: 0px;
  }
  .additional {
    width: 100% !important;
    padding: 0 15px 15px 15px !important;
  }
  .company-info-container {
    border: none;
  }
  .company-location-header {
    margin-bottom: 15px;
  }
}
