.table-container {
  border-radius: 11px;
  overflow: scroll;
  width: 100%;
  // width: calc(100vw - 616px);
}

.excel-parser {
  width: 52vw;
}

.product-edit-parcel {
  width: 100%;
  border-radius: 11px;
  overflow: scroll;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #efefef;
  border-top: none;
  border-bottom: none;
  text-align: center;
}

.table th {
  background-color: #f4f4f4;
}

@media (min-width: 200px) and (max-width: 900px) {
  .excel-parser {
    width: 100%;
    .table-container {
      width: 100%;
      overflow: scroll;
    }
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .excel-parser {
    width: 65vw;
  }
}
