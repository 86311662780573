.investment-preference-view {
  padding-top: 2rem;
  padding-right: 2rem;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
}

.inv-pref-product {
  width: 40%;
  height: fit-content;
  margin-bottom: 5px;
  display: flex;
  label,
  p {
    min-width: 50%;
  }
  label {
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #1a1d1f;
  }
  p {
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #1a1d1f !important;
  }
}

.inv-pref-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10%;
  margin-top: 1.5rem;
}

.inv-accordian-header {
  border: none;
  margin-bottom: 0;
  align-items: center;
  margin-left: 25px;
  min-width: max-content;
}

.inv-remove {
  position: absolute;
  font-size: 14px;
  right: 4%;
  z-index: 1000;
  margin-right: 10px;
  color: #be0f00;
  font-weight: 550;
}

.inv-btn {
  position: absolute;
  font-size: 14px;
  right: 4%;
  z-index: 1000;
  margin-right: 10px;
}
