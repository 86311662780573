.roadshowContainer {
  padding-top: 16px;
}

.roadshowHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 1rem 1rem;
}

.roadshowMap {
  border-top: 1px solid #efefef;
  position: relative;
}

.companyName {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  margin-bottom: 5px;
  cursor: pointer;
}

.companyType {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #6f767e;
  padding-bottom: 4px;
  cursor: pointer;
}

.InvestorListWrap {
  border: 1px solid #efefef;
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 60px;
  border-bottom: none;
  max-height: calc(100vh - 152px);
  transition: padding 0.3s ease;
}

.InvestorListWrapClicked {
  background-color: #efefef;
  width: 100%;
  padding: 10px;
  padding-right: 0;
  padding-left: 1rem;
  transition: background-color 0.3s ease;
}

.investorListWrap {
  padding: 15px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-bottom: 1px solid #efefef;
  padding-right: 0;
}

.ItemDatesInfo {
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #6f767e;
}

.dataDiv {
  position: absolute;
  top: 0;
  min-width: 25vw;
  background-color: white;
  margin-right: 20px;
  max-height: 100vh;
  min-height: 100vh;
  border-right: 1px solid #efefef;
  overflow-y: scroll;
}

.googleGlobalWrap {
  position: absolute;
  top: 5vh;
  left: 28vw;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  width: 30%;
  border-radius: 16px;
  z-index: 1;
  height: 70%;
  overflow: hidden;

  .googleGlobalInnerWrap {
    width: 100%;
    border-radius: 16px;
    z-index: 1;
    height: 100%;
    overflow-x: auto;
    overflow: scroll;
  }

  .googleGlobalInnerWrap::-webkit-scrollbar {
    background: #efefef;
    width: 2.5px;
    scroll-behavior: smooth;
    border-radius: 12px;
    height: 2px;
  }

  img {
    // width: 365px;
    width: 100%;
    height: 120px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.imgcloseWrap {
  display: flex;
  gap: 5px;
  position: relative;

  :global(.close-icon-pop-up) {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    right: 0%;

    i {
      padding: 8px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      font-size: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

.companyHeaderRoad {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.LogoRoadMap {
  width: 90px !important;
  height: 90px !important;
  position: absolute;
  border-radius: 10px !important;
  border: 1px solid #efefef;
  bottom: -25%;
  left: 5%;
}

.companyNamepopup {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 2px;
  margin-top: 10px;
}

.viewCta {
  position: absolute;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  bottom: -34%;
  right: 4%;
  padding: 6px 20px;
  border: 1px solid rgb(192, 196, 201);
  color: black;
  border-radius: 20px;
}

// @media (min-height: 900px) and (max-height: 1000px) {
//   .dataDiv {
//     min-height: 100vh;
//     overflow-y: scroll;
//   }
// }

// @media (min-height: 1000px) and (max-height: 1400px) {
//   .dataDiv {
//     min-height: 120vh;
//     overflow-y: scroll;
//   }
// }
