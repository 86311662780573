.auth_screen {
  display: flex;
  min-height: 70vh;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column-reverse; /* Change to column for smaller screens */
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    gap:  2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 400px) {
    gap: 4rem;
  }
}

.auth_image {
  flex: 1;
  background-image: url('../../../assets/images/login.png');
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none; /* Hide the auth-image on smaller screens */
  }
}

.auth_cards {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 50%; /* Adjust the maximum width for the cards */

  .auth_card {
    border-radius: 1.5rem;
    background-color: #EAF5FF;
    text-align: center;
    padding: 2rem;
    max-width: 36rem;
    width: 100%; /* Make the card width 100% */
  }

  .btn_div {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width : 1154px) and (min-width : 900px) {
    padding: 0 2rem;
    max-width: 100%;

  }

  @media (max-width: 900px)  {
    max-width: 100%;
    padding: 0 2rem;
  }

  @media (max-width : 600px) {
    padding: 0;
    
  }

}

// .auth_content {
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   max-width: 50%; /* Adjust the maximum width for the content */
  
//   @media (max-width: 900px)  {
//     max-width: 100%;
//   }
// }

.auth_content{
  @media (min-width: 1000px) {
  display: flex;
  flex-direction: column;
  // align-items: center;
  display: flex;
  flex-direction: column;
  /* max-width: 100%; */
  align-items: center;
  justify-content: center;
  flex: 1;
  }

  width: 63%;


}

.partition {
  width: 1px;
  border-left: 1px solid #efefef;
  height: 65vh;
  margin: 3rem 0;
  display: none; /* Hide the partition on smaller screens */

  @media (min-width: 999px) {
    display: block; /* Show the partition on larger screens */
  }
}

// .auth_child {
//   flex: 1;
//   max-width: 460px;
//   min-width: 0;
//   width: 100%;
//   box-sizing: border-box;
// }

.logo_image {
  width: 167px !important;
  margin-top: 20px;
  margin-left: 12%;
}

@media screen and (max-width: 1000px) and (min-width: 768px) {

}

@media (max-width: 768px) {
  .auth_content {
    width: 100%; /* Take up the entire width of the screen on smaller screens */
  }
}

.auth_child{
  width: 100%;

  @media screen and (min-width: 1110px) {
    width: 70%;
  }
}
