.saved-list-container {
  padding: 40px 60px 0 0;
}

.saved-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 4rem;
  border-bottom: 1px solid var(--30, #efefef);
}

.saved-item {
  padding: 20px 0;

  display: flex;
  align-items: center;
  flex: row;
  gap: 16px;
}

.product-image {
  width: 80px;
  height: 80px;
}

.saved-list-header {
  // padding-right: 10px;
  display: flex;
  justify-content: space-between;
}
.Saved-remove-btn-phone {
  display: none;
}
.Saved-delete-icon-container {
  fill: #be0f00;
}

@media (min-width: 200px) and (max-width: 900px) {
  .saved-list-container {
    overflow: hidden;
  }
  .saved-list-container {
    padding: 25px 15px;
  }

  .saved-item-container {
    padding: 0;
  }

  .manage-icon {
    border: none;
    background: none;
    color: #be0f00;
    padding-right: 0.75rem;
  }
}

.non-saved {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 200px) and (max-width: 480px) {
  .saved-list-header {
    width: 100vw !important;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
  }
}
