.main-toggle-btn {
  background: none;
  font-size: 400;
  border: none;
  transition: font-weight 0.3s ease-in-out; /* Adjusted timing function */
}

.main-toggle-btn:hover {
  font-weight: 500 !important;
  transition-delay: 0.3s;
}
.conditions-container {
  padding: 24px 60px 48px 60px;
  width: 100%;
  font-family: "Inter";
  color: rgb(26, 29, 31);

  h1 {
    font-weight: 700;
    font-size: 30px;
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
  }

  h6 {
    font-weight: 600;
    font-size: 12px;
  }

  p {
    font-size: 16px;
  }

  .conditions-container-header {
    margin-bottom: 1.5rem;

    .conditions-container-header-heading {
      margin: 20px 0px 5px 0px;
      font-size: 30px;
      font-weight: 700;
    }
  }
}

@media (min-width: 300px) and (max-width: 900px) {
  .Breadcum-policy-container {
    width: 100%;
    overflow: scroll;
    text-wrap: nowrap;
  }

  .conditions-container {
    padding: 12px 30px 24px 30px;
  }

  .conditions-container-header {
    .conditions-container-header-heading {
      margin: 10px 0px 5px 0px;
      font-size: 30px !important;
      font-weight: 700;
    }
  }
}
