/* Footer.css */
@import "../../../assets/scss/style.scss";
.site-footer {
  background-color: $dark;
  color: $white;
  border-top: 1px solid $lightGrey;
  margin-top: auto;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 60px 0;
}

.footer-column {
  flex: 1;
  margin-bottom: 20px;

  h3 {
    font-family: $plus_jakara_sans;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  li,
  span {
    color: var(--30, #efefef);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .brand {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 24px;

    #img-1 {
      height: 29px !important;
    }
    img {
      height: 20px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    i {
      width: 20px;
      height: 20px;
    }
    span {
      margin-bottom: 0 !important;
      margin-left: 10px;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

.copyright-info {
  border-top: 1px solid #434343;
  text-align: center;
  padding: 26px;
  color: $lightGrey;

  p {
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .footer-columns {
    padding: 30px 40px 0;
  }

  .footer-column {
    .brand {
      img {
        height: 16px;
      }
      #img-1 {
        height: 24px !important;
      }
    }
  }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .footer-column {
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-right: 0;

    li {
      text-align: center;
    }
  }
}
