.video-div {
  height: 335px;
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-div iframe {
  width: 100%;
  height: 100%;
}

#hidden-iframe {
  display: none;
}

.video-info-div {
  .details {
    display: flex;
    justify-content: space-between;
  }

  .description {
    color: var(--100, #1a1d1f);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.14px;
  }

  label{
    margin-bottom: 0;
  }
}

.edit-video-icon,.video-delete-icon{
  fill: #4e5660;
}