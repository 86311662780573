@import "../../../../../assets/scss/variables.scss";

.textStyle {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--10, #f4f4f4);
}

.viewStyle {
  border-radius: 6px;
  border: 1px solid #efefef;
  background-color: #fff;
  padding: 7px 10px;
  align-items: center;
  font-size: 13px;
  gap: 5px;
  color: #1a1d1f;
  font-weight: 600;
  width: max-content;
}

.company_team {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 2rem;
  div:is(.p) {
    width: 200px;
    text-wrap: wrap;
    text-align: center;
  }
}

.address-card:last-child {
  margin-bottom: 0 !important;
}

.data-container {
  min-height: 400px;
}

.status-active,
.status-active-small {
  color: var(--Ab-17, #6da35a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: -0.14px;
}

.status-active-small {
  font-size: 12px;
}

.status-inactive,
.status-inactive-small {
  color: #be0f00;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: -0.14px;
}

.status-inactive-small {
  font-size: 12px;
}

.company-overview-container {
  label {
    margin-bottom: 0 !important;
  }
}

.social-channels {
  display: flex;
  gap: 1.25rem;
}

.company-product-detail-container,
.investment-preferences-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin: 1rem 0;
  row-gap: 1rem;

  p {
    margin-bottom: 0 !important;
  }

  div {
    overflow-wrap: break-word;
  }
}

.p-light-small-product-company {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 1.25rem;
  letter-spacing: -0.14px;
  font-weight: 500;
  color: var(--80, #6f767e);
}
.p-dark-product-company {
  font-family: Inter;
  font-style: normal;
  line-height: 1.25rem;
  letter-spacing: -0.14px;
  font-size: 12px;
  font-weight: 600;
  color: #1a1d1f;
}

.insights-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .insight-card {
    display: flex;
    gap: 1.5rem;
    // cursor: pointer;

    .thumbnail-image,
    .thumbnail-card {
      min-width: 10rem;
      height: 8rem;
      border-radius: 16px;
      background-color: #f4f4f4;
    }

    .thumbnail-card {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: #1a1d1f;
        font-size: 48px;
        height: 48px;
        width: 48px;
        line-height: normal;
      }
    }

    .insight-col {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;

      p {
        overflow-wrap: anywhere !important;
      }
    }
  }
}

.address-card-container-wrapper {
  border-radius: 12px;
  overflow: hidden;
}

.address-card-container {
  padding-bottom: 0 !important;
  max-height: 500px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
}

.address-single-card {
  margin-bottom: 1.5rem !important;
}

.address-card:last-child {
  margin-bottom: 0px !important;
}

.Lock-white-container {
  fill: #f4f4f4;
}

.black-fill {
  fill: #1a1d1f !important;
}

.insight-link-icon-container {
  width: 28px;
  height: 28px;
}
.extar-description-styles {
  p {
    display: inline;
  }
}
@media (min-width: 200px) and (max-width: 480px) {
  .company-product-detail-container {
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }

  .investment-preferences-container {
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }

  .company_team {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
  }

  .main-box-company {
    padding-right: 20px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .insight-card {
    flex-direction: column;
    gap: 0 !important;

    img {
      width: 100% !important;
      height: 200px !important;
      object-fit: cover;
    }
  }

  .social-channels {
    width: 100%;
    overflow: scroll;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .company_team {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
  }

  .main-box-company {
    padding-right: 20px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .Type-size-overview-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }

  .social-channels {
    width: 100%;
    overflow: scroll;
  }
}

.mapStyle {
  width: 143px;
  height: 40px !important;
  margin-top: 10px !important;
}

.company-blog-card {
}

.company-blog-card {
  .h4 {
    overflow-wrap: break-word;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px;
  }
}

.company-blog-card:last-child {
  margin-bottom: 0 !important;
}

.company-blog-image {
  width: 30%;
  height: 20%;
  border-radius: 8px;
  border: 1px dolif #efefef;
  aspect-ratio: 3/2;
  object-fit: cover;
}
