.work-view {
  padding-top: 2rem;
  padding-right: 2rem;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
}

.work-remove {
  position: absolute;
  font-size: 14px;
  right: 4%;
  z-index: 1000;
  margin-right: 10px;
  color: #be0f00;
  font-weight: 550;
}

.work-header {
  border: none;
  margin-bottom: 0;
  align-items: center;
  margin-left: 25px;
  min-width: max-content;
  font-weight: 600;
  font-size: 18px;
}
