@import "../../../assets/scss/variables";

.info {
  color: #3d9be9;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.14px;
  cursor: pointer;
}

.focused {
  border: 1px solid $primary;
  background-color: $white !important;
}

.input_group {
  .input_pass {
    width: 100%; // By default, take 100% width
  }

  @media (max-width: 350px) {
    .input-pass {
      width: 40% !important; // Set width to 40% when the screen size is less than 350px
    }
  }
}

//   @media (max-width: 350px) {

//  }
