.accordion .accordion-item {
  margin-bottom: 24px !important;
  border-radius: 16px !important;
  border: 1px solid #efefef !important;
}

.accordion .accordion-header,
.accordion-button {
  border-radius: 16px !important;
}

.accordion .accordion-button {
  height: max-content !important;
  min-height: 61px !important;
  font-size: 18px;
  font-weight: 700;
  font-family: Plus Jakarta Sans, sans-serif !important;
}

.accordion .accordion-button:not(.collapsed) {
  color: $dark !important;
  background-color: #ffff;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 700;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-body {
  border-top: 1px solid #efefef;
  padding-top: 1rem !important;

  label {
    margin-top: 12px !important;
  }

  label:first-child {
    margin-top: 0 !important;
  }
}

.product-taxonomy-accordian .accordion .accordion-item {
  margin-bottom: 12px !important;
  border-color: #e5e3e3 !important;
}
