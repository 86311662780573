
@media (min-width:200px) and (max-width:600px){
    .modal-content{
      width: 100vw !important;
      scale: 0.9;
      overflow-x: hidden !important;
    }
  }
  @media (min-width:600px) and (max-width:900px){
    .modal-content{
      scale: 1;
      overflow-x: hidden !important;
    }
  }