.investorWrap {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.twoBodyWrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
