.circle-dropdown-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;

    i{
        line-height: normal;
        font-size: 18px;
        height: 18px;
    }
}