@import "../../../assets/scss/variables.scss";

.search,
.search_lg,
.search_md,
.search_sm {
  flex: 1;
  max-width: 300px;

  .search-prepend-focused,
  .search-focused {
    background-color: $white !important;
    border: 1px solid $primary !important;
  }

  .search-focused {
    border-left: none !important;
  }

  .search-prepend-focused {
    border-right: none !important;
  }
}

.form-control {
  transition: none;
}

.search input:focus,
.search_lg input:focus,
.search_md input:focus,
.search_sm input:focus {
  outline: none;
  box-shadow: none;
}

.search .input-group,
.search .input-group-prepend .input-group-text i,
.search .form-control::placeholder,
.search_lg .input-group,
.search_lg .input-group-prepend .input-group-text i,
.search_lg .form-control::placeholder,
.search .form-control::placeholder,
.search_md .input-group,
.search_md .input-group-prepend .input-group-text i,
.search_md .form-control::placeholder input::placeholder,
.search_sm .input-group,
.search_sm .input-group-prepend .input-group-text i,
.search_sm .form-control::placeholder input::placeholder {
  color: #6f767e !important;
}

::-webkit-input-placeholder {
  /* Safari, Chrome, and Opera */
  color: #6f767e;
}

.search input {
  height: 40px !important;
}

.search_sm input {
  height: 37px !important;
}

.search_lg input {
  height: 48px !important;
}

.search_md input {
  height: 42px !important;
}

.search .input-group-prepend .input-group-text,
.search_lg .input-group-prepend .input-group-text,
.search_md .input-group-prepend .input-group-text,
.search_sm .input-group-prepend .input-group-text {
  height: 40px;
  border-radius: 24px 0 0 24px;
  border: none;
  background-color: #f4f4f4;
  padding-right: 0;
}

.search_lg .input-group-prepend .input-group-text {
  height: 48px !important;
}

.search_md .input-group-prepend .input-group-text {
  height: 42px !important;
}

.search_sm .input-group-prepend .input-group-text {
  height: 37px !important;
}

.search .form-control,
.search_lg .form-control,
.search_md .form-control,
.search_sm .form-control {
  border-radius: 24px;
  border: none;
  background-color: #f4f4f4;
  color: #6f767e;
}

.white {
  .input-group-prepend .input-group-text,
  .input-group input {
    background-color: white;
    border: 1px solid #ced5de;
  }

  .input-group input {
    border-left: none;
    border-right: 1px solid #ced5de !important;
  }
}

// .white:hover {
//   .input-group-prepend {
//     border: 1px solid $primary !important;
//   }
// }

@media screen and (max-width: 600px) {
  .search,
  .search_lg,
  .search_md,
  .search_sm {
    max-width: 100%;
    flex: none;
  }
}
