@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}

.view-data-container {
  width: 78vw;
  height: 30vh;
  background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
  animation: shimmer 1s infinite linear;
  background-size: 300%;
  background-position-x: 100%;
  padding: 15px;
  .view-sr-btn {
    width: 120px;
    height: 35px;
    border-radius: 15px;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    animation: shimmer 1s infinite linear;
    background-size: 300%;
    background-position-x: 100%;
  }
  .view-main-sr {
    gap: 10px;
    .view-sr-pic {
      height: 120px;
      width: 120px;
      background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
      animation: shimmer 1s infinite linear;
      background-size: 300%;
      background-position-x: 100%;
    }
    .view-sr-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .contetnt-video-lr {
        width: 200px;
        height: 30px;
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
      }
      .contetnt-video-md {
        width: 140px;
        height: 30px;
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
      }
    }
  }
}
