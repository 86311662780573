.select-div {
  border-radius: 16px;
  border: 1px solid var(--30, #efefef);
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  height: fit-content;
}

.selector-button {
  padding: 8px 20px;
  background-color: #fff;
  color: var(--100, #1a1d1f);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: none;
  line-height: 28px; /* 200% */
  text-align: left;
  transition: background-color 0.3s, color 0.3s; /* Added transitions */
}

.selector-button:hover {
  color: #3d9be9;
}

.selector-active {
  color: #3d9be9;
  background-color: #f4f4f4;
  border-left: 2px solid #3d9be9;
  font-weight: 600;
}

@media (min-width: 200px) and (max-width: 900px) {
  .select-div {
    flex-direction: row;
    min-width: 100%;
    overflow: scroll;
    border: none;
    border-bottom: 2px solid var(--30, #efefef);
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .selector-button {
    min-width: max-content;
  }
  .selector-active {
    border-bottom: 2px solid #3d9be9;
    border-left: none;
    background-color: white !important;
  }
}
