.social-info-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid var(--30, #efefef);
    padding: 24px 22px;
    margin-bottom: 15px;
  
    span {
      color: #1A1D1F;
      font-size: 18px;
      font-weight: 600;
      font-family: Inter;
    }
  }