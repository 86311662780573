@import '../../../assets/scss/variables.scss';

.no-content-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6%;
    padding-bottom: 5%;

    img { 
        height: 11rem;
    }
}

.no-content-title {
    color: $dark;
    text-align: center;
    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 700;
    line-height: 1.25rem; /* 20px / 16px */
    margin-bottom: 0.75rem; /* 12px / 16px */
}

.no-content-subtitle {
    color: #697184;
    font-size: 0.875rem; /* 14px / 16px */
    font-weight: 500;
}

.no-content-circle {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    background-color: $light;
    align-items: center;
    justify-content: center;
    i {
        font-size: 32px;
        color: $dark;
    }
}