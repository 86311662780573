.view-info-container {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;

  .label {
    flex: 0 0 250px;
    margin-right: 10px;

    color: #6f767e;
    font-weight: 500;
    font-size: 14px;
  }

  .data {
    flex: 1;
    font-weight: 500;
    color: #1a1d1f;
    font-size: 14px;
    display: flex;
    align-items: center;
    .colon {
      display: inline;
      color: #6f767e;
      font-weight: 500;
      font-size: 14px;
      margin-right: 1.5rem;
    }
  }

  .view-request-dropdown {
    display: flex;
    width: 70%;
    align-items: center;
  }
}

.request-info-container:first-child {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.edit-video-icon {
  fill: #4e5660;
}
.no-border-request-container {
  border: none !important;
  margin-top: 0 !important;
}
.margin-request-conatiner {
  margin: 10px 3% !important;
}
.company-rightUpWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.rightUpArrow {
  margin-bottom: 4px;
}
@media (min-width: 200px) and (max-width: 480px) {
  .request-heading-container {
    padding-left: 5px;
  }
  .request-padding-space {
    padding: 0 5px !important;
  }
  .remove-top-margin-request {
    margin-top: 5px !important;
  }
  .filter-mobile-container-btn {
    scale: 0.8;
    transform-origin: top;
    i {
      margin-right: 0 !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 900px) {
  .request-heading-container {
    padding-left: 5px;
  }
  .margin-request-conatiner {
    margin: 10px 1% !important;
  }
  .request-padding-space {
    padding: 0 5px !important;
  }
  .requests-tab-div ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
}
