::-webkit-scrollbar {
  background: transparent;
  width: 15px;
  scroll-behavior: auto;
  border-radius: 12px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 20px;
  height: 30px;
}

div::-webkit-scrollbar-thumb {
  background: #bbb; /* Adjust the color as needed */
  border-radius: 10px;
  height: 10px; /* Adjust the height as needed */
}

div::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 5px; /* Adjust the height for the horizontal scrollbar */
}

@media (orientation: horizontal) {
  ::-webkit-scrollbar-thumb {
    height: 5px; /* Adjust the height for the horizontal scrollbar */
  }
}
