@import "../../../assets//scss/variables";

.save {
  margin-top: 0 !important;
  border-radius: 24px !important;
  height: 48px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 24px !important;
  font-weight: 600 !important;
  background-color: #ffffff;

  i {
    margin-right: 6px;
    line-height: normal;
    width: 16px;
    height: 16px;
  }
}
.not_clicked {
  border: 1px solid $primary;
  color: $primary;
}

.clicked {
  border: 1px solid #9a9fa5;
  color: #6f767e;

  i {
    color: $primary;
  }
}
.saved_company_icon_container {
  fill: #3d9be9 !important;
}
