.education-view {
  padding-top: 2rem;
  padding-right: 2rem;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
}

.certification-header,
.certification-detail,
.education-header,
.education-detail,
.company-header,
.company-detail {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certification-detail,
.education-detail {
  margin-bottom: 0.5rem;
}

.remove {
  color: #be0f00;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.AddWork-info-div {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid var(--30, #efefef);
  padding: 24px 22px;
  margin-bottom: 15px;
  align-items: center;
}
.work-building-wrap {
  display: flex;
  gap: 10px;
}
.work-building-img {
  border: 1px solid #333;
  padding: 20px;
  border-radius: 31px;
}
.work-email {
  padding-left: 10px;
  color: #333;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00875rem;
}
.work-title {
  color: var(--100, #1a1d1f);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.work-company-name {
  color: var(--100, #1a1d1f);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00875rem;
  padding-right: 10px;
}
.work-start-date {
  color: var(--100, #1a1d1f);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00875rem;
}
.company-name-email {
  padding-top: 0.38rem;
  padding-bottom: 0.5rem;
}
.work-details {
  display: flex;
  justify-content: space-between !important;
  gap: 326px;
}
.emailDot {
  height: 6px;
  width: 6px;
  background-color: #c0c4c9;
  border-radius: 50%;
  display: inline-block;
}
.images-style {
  border-radius: 61px;
}

.edu-remove {
  position: absolute;
  font-size: 14px;
  right: 4%;
  z-index: 1000;
  margin-right: 10px;
  color: #be0f00;
  font-weight: 550;
}

.edu-accordian-header {
  border: none;
  margin-bottom: 0;
  align-items: center;
  margin-left: 25px;
  min-width: max-content;
}
