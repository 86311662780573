@import "../../../../assets/scss/variables.scss";

.Portfolio-Insight-Card-Container {
  width: 100%;

  .Portfolio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    .Portfolio-heading-information {
      margin-bottom: 20px;
      h1 {
        color: #1e273b;
        font-family: $plus_jakara_sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 10px;
      }
      span {
        color: var(--80, #6f767e);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
      }
    }
    button {
      background-color: black;
      border: none;
      border-radius: 50px;
      padding: 0.5rem;
    }
  }
  .Portfolio-Insight-Cards-inner {
    display: flex;
    gap: 1.999%;
    .Portfolio-Insight-Single-Card {
      cursor: pointer;
      width: 23%;
      position: relative;
      .Portfolio-Insight-Single-Card-Pic {
        width: 100%;
        height: 245px;
        border-radius: 20px;
      }
      h1 {
        color: var(--100, #1a1d1f);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        margin-bottom: 0;
      }
      span {
        color: var(--90, #4e5660);
        font-family: $inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
      }
      .Portfolio-content-pic {
        position: absolute;
        top: 15px;
        left: 15px;
        color: var(--100, #1a1d1f);
        border: 0.5px solid #6f767e;
        font-family: $inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        display: inline-flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--0, #fff);
      }
    }
  }
}

.Portfolio-Insight-Single-Card-Pic {
  object-fit: contain;

  img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .Portfolio-Insight-Card-Container {
    width: 100vw;
    padding-left: 10px;
    .Portfolio-heading-information {
      margin-bottom: 20px;
      h1 {
        margin-bottom: 10px;
      }
    }
    .Portfolio-Insight-Cards-inner {
      overflow-x: scroll;
      padding-right: 10px;
    }
    .Portfolio-Insight-Single-Card {
      min-width: 260px;
    }
  }
  .Portfolio-Insight-Cards-inner {
    overflow-x: scroll;
    :nth-child(4) {
      margin-right: 10px !important;
    }
  }
  .Portfolio-Insight-Single-Card {
    min-width: 260px;
  }
}
