.filter-item-container {
  display: flex;
  align-items: start;
  gap: 16px;
  flex-wrap: wrap;

  .active {
    color: #3d9be9;
    border: 1px solid #3d9be9;
    transition: color 0.3s ease, border 0.3s ease; /* Add transition properties */
  }
}

.filter-border {
  flex-wrap: nowrap;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 30px;
}

.left-side-filters {
  width: 80%;
}

.filter-item {
  border-radius: 60px;
  border: 1px solid #c0c4c9;
  background-color: #fff;
  padding: 10px 24px;
  cursor: pointer;
  transition: background 0.3s ease; /* Add transition property for background */
}

.delete-option {
  min-width: max-content;
}
