.keyLinkWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.keyLink-info-div {
  display: flex;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--30, #efefef);
  padding: 24px 22px;
  margin-bottom: 15px;
  justify-content: space-between;
}
.keyLink-Images {
  margin-left: 12px;
}
.keyLink-title-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keyLink-title {
  color: var(--100, #1a1d1f);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.keyLink-description {
  color: var(--80, #6f767e);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.00875rem;
}
