.image-icon{
    width: 60px;
    height: 60px;
    border: 1px solid #EFEFEF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

@media (min-width: 200px) and (max-width:480px){
    .image-icon{
        min-width: 60px;
    }
}