.Investor-DataBase-Statistics-container {
    display: flex;
    justify-content: space-around;
    padding: 70px 0px;

    .Investor-DataBase-Statistics {
        display: flex;
        flex-direction: column;
        align-items: center;

        .Investor-DataBase-Statistics-Number {
            color: #1E273B;
            font-family: "Plus Jakarta Sans";
            font-size: 38px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }

        .Investor-DataBase-Statistics-cateegory {
            color: var(--80, #6F767E);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.16px;
        }
    }
}

@media (min-width: 200px) and (max-width: 900px) {
    .Investor-DataBase-Statistics-container {
        padding: 30px 20px 30px 10px;
        justify-content: start;
        overflow-x: scroll;
        width: 100vw;
        gap: 90px;


        .Investor-DataBase-Statistics {
            min-width: fit-content;
        }
    }
}