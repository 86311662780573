.reset_btn,
.reset_small_btn {
  min-width: max-content;
  position: relative;
  border: 1px solid #3d9be9;
  height: 42px;
  border-radius: 30px;
  padding: 11px 17px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-right: 10px;

  label {
    color: #3d9be9 !important;
    cursor: pointer;
  }
}

.reset_small_btn {
  height: 37px;
  padding: 11px 13px;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  label {
    margin-bottom: 0;
  }
}
