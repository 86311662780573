@import "../../../assets/scss/variables.scss";
.tag-input-container {
  padding: 0.75rem;
  border-radius: 0.625rem;
  background-color: var(--10, #f4f4f4);
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  outline: none;
  border: none;
}

.tag {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  border: 1px solid #efefef;
  padding: 0.4375rem 0.625rem;
  background-color: #fff;
  color: var(--100, #1a1d1f);

  font-family: $inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 114.286% */
  letter-spacing: -0.00875rem;
}

.remove-button {
  background: none;
  border: none;
  width: 1rem;
  height: 1rem;
  color: #6f767e;
  cursor: pointer;
  font-size: 0.6rem;
  margin-left: 4px;
}

.tag-input-feild {
  border: none !important;
  display: inline;
  width: 10%;
  background-color: #f4f4f4;
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
  font-size: 14px;
  padding-left: 5px;
}

.tag-input-feild:focus {
  background-color: #f4f4f4;
}

.tag-close-btn-container {
  width: 14px;
  height: 14px;
}
