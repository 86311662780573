@import "../../../../assets/scss/variables.scss";

.Image-Container {
  width: 100%;
  position: relative;

  .Image-Pic {
    width: 100%;
    height: 360px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .Pic-Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    left: 3%;
  }

  .Pic-Heading,
  .Pic-Heading-array {
    width: 352px;
    color: var(--0, #fff);
    font-family: $plus_jakara_sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 46px;
  }

  .Btns {
    display: flex;
    gap: 1.2rem;
    flex-wrap: wrap;
  }

  .Pic-Content-fund-directory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    left: 3%;

    .Pic-Content-fund-Heading {
      width: 352px;
      color: var(--0, #fff);
      font-family: $plus_jakara_sans;
      font-size: 32px;
      font-weight: 700;
      line-height: 46px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 310px;

    .Image-Pic {
      height: 310px !important;
    }

    .Pic-Content,
    .Pic-Content-fund-directory {
      text-align: center;
      width: 249px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .Pic-Heading,
    .Pic-Content-fund-Heading {
      font-size: 24px;
      line-height: 32px;
    }

    .Btns {
      flex-direction: column;
      gap: 8px;
    }
  }

  @media (min-width: 480px) and (max-width: 900px) {
    .Pic-Heading-array {
      font-size: 28px !important;
    }
  }
}
