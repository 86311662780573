.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
  
    i {
      font-size: 14px;
    }
  }
  
  .modal {
    display: none;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1200;
  }
  
  .modal-content {
    background-color: white;
    width: 550px;
    border-radius: 30px; /* Default border-radius for all sides */
    overflow: auto; /* Enable scrollbar when content overflows */
    max-height: 90vh; /* Set a maximum height to trigger the scrollbar */
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
  }
  
  /* Adjust the border-radius when there is a scrollbar */
  .modal-content.scrollable {
    border-radius: 30px 30px 0 0;
  }
  
  
  .modal-body {
    padding-left: 32px;
    padding-right: 32px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .business-modal-content {
    background-color: white;
    padding: 20px;
    width: 800px;
    border-radius: 16px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    height: auto;
    max-height: 97vh; /* Adjust the height as needed */
    overflow-y: auto;
  }
  
  .modal-heading {
    color: var(--100, #1a1d1f);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  
  .modal.active {
    display: flex;
  }
  
  .delete-modal-subtitle {
    color: #545c6e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
  }
  
  .cancel-btn {
    border-radius: 8px;
    border: 1px solid #d9dde7;
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cancel-btn > span {
    color: #1e273b;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  
  .confirm-btn-delete-modal {
    width: 352px !important;
    height: 50px !important;
    border-radius: 8px !important;
    margin-bottom: 20px;
  }
  
  .modal-description {
    color: #545c6e;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  